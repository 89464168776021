import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { getUserPlans, getCardPlans } from '../../../store/actions';
import { CardPlan } from '../../organisms';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const CardPlans = (props) => {
  const classes = useStyles();
  const { getUserPlans, getCardPlans, cardPlans } = props;

  useEffect(() => {
    getUserPlans();
  }, [getUserPlans]);

  useEffect(() => {
    getCardPlans();
  }, [getCardPlans]);

  return (
    <>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4' component='h1'>
            Select Your Card
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CardPlan cardPlans={cardPlans} />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { plans } = state;
  return {
    cardPlans: plans.cardPlans,
  };
};

const mapDispatchToProps = {
  getUserPlans,
  getCardPlans,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPlans);
