import { takeEvery, takeLatest } from 'redux-saga/effects';
import * as actionType from '../actions/actionType';

import { fetchAccountAllSaga, fetchAccountSaga } from './account';
import { fetchCustomerSaga } from './customer';
import { getAllTransactionSaga } from './transaction';
import {
  getCardsSaga,
  getCardDetailsSaga,
  getCardSecuredDetailsSaga,
} from './cards';
import { getBeneficiariesSaga } from './beneficiaries';
import { getAllNotificationsSaga } from './notifications';
import {
  getPointsBalanceSaga,
  getTasksSaga,
  getRewardsHistorySaga,
} from './wittyRewards';
import { getCardPlansSaga, getUserPlansSaga } from './plans';
import { getLimitsAndFeesSaga } from './limits';

export default function* watchWebBanking() {
  yield takeEvery(actionType.ACCOUNT_START, fetchAccountSaga);
  yield takeEvery(actionType.ACCOUNT_ALL_START, fetchAccountAllSaga);
  yield takeEvery(actionType.CUSTOMER_START, fetchCustomerSaga);
  yield takeLatest(actionType.GET_ALL_TRANS, getAllTransactionSaga);
  yield takeLatest(actionType.GET_CARDS, getCardsSaga);
  yield takeLatest(actionType.GET_CARD_DETAILS, getCardDetailsSaga);
  yield takeLatest(
    actionType.GET_CARD_SECURED_DETAILS,
    getCardSecuredDetailsSaga
  );
  yield takeLatest(actionType.GET_BENEFICIARIES, getBeneficiariesSaga);
  yield takeEvery(actionType.GET_ALL_NOTIFICATIONS, getAllNotificationsSaga);
  yield takeEvery(actionType.GET_WCEP_POINTS_BALANCE, getPointsBalanceSaga);
  yield takeLatest(actionType.GET_WCEP_TASKS, getTasksSaga);
  yield takeLatest(actionType.GET_REWARDS_HISTORY, getRewardsHistorySaga);
  yield takeLatest(actionType.GET_CARD_PLANS, getCardPlansSaga);
  yield takeLatest(actionType.GET_USER_PLANS, getUserPlansSaga);
  yield takeLatest(actionType.GET_LIMITS_AND_FEES, getLimitsAndFeesSaga);
}
