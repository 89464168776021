import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Routes } from '../../../utils/constant';
import Header from '../Header/Header';
import classes from './Layout.module.scss';
import SubHeader from '../SubHeader/SubHeader';
import { subNavList } from '../../../utils/subNavigation';

const Layout = (props) => {
  const routesWithHeader = Object.values(Routes).filter(
    (path) => path !== '/' && path !== undefined
  );
  return (
    <div className='wp'>
      <Switch>
        <Route path={routesWithHeader} component={Header} />
      </Switch>
      <SubHeader subNavList={subNavList} className={classes.sub_header} />
      <div className={classes.wp_content}>{props.children}</div>
    </div>
  );
};

export default Layout;
