import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getLimitsAndFees } from '../../../store/actions';
import { Loader } from '../../atoms';
import { Grid, Typography } from '@material-ui/core';
import { parseParams } from '../../../utils/utils';
import { LimitsInfo } from '../../organisms';

const LimitsAndFeesInfo = (props) => {
  const { getLimitsAndFees, loading, limits, feesAndLimit } = props;

  useEffect(() => {
    if (limits.length === 0) {
      getLimitsAndFees();
    }
  }, [getLimitsAndFees, limits]);

  if (loading) {
    return <Loader />;
  }

  if (Object.keys(feesAndLimit).length === 0) {
    return (
      <Grid container justifyContent='center' alignItems='center'>
        <Typography variant='body1'>No data found</Typography>
      </Grid>
    );
  }

  return (
    <>
      <LimitsInfo feesAndLimit={feesAndLimit} />
    </>
  );
};

const mapStateToProps = (state) => {
  const { limits } = state;

  const { code } = parseParams(window.location.search);
  const feesAndLimit = limits.data?.find((obj) => obj.code === code) ?? {};
  return {
    loading: limits.loading,
    limits: limits.data,
    feesAndLimit: feesAndLimit,
  };
};

const mapDispatchToProps = {
  getLimitsAndFees,
};

export default connect(mapStateToProps, mapDispatchToProps)(LimitsAndFeesInfo);
