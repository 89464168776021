import {
  GET_BENEFICIARIES,
  GET_BENEFICIARIES_SUCCESS,
  GET_BENEFICIARIES_FAILED,
} from './actionType';

export const getBeneficiaries = () => {
  return {
    type: GET_BENEFICIARIES,
  };
};

export const getBeneficiariesSuccess = (data) => {
  return {
    type: GET_BENEFICIARIES_SUCCESS,
    data,
  };
};

export const getBeneficiariesFailed = (error) => {
  return {
    type: GET_BENEFICIARIES_FAILED,
    error,
  };
};
