import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UrlText, i18nLanguages } from '../../../utils/constant';
import './SubHeader.scss';
import {
  Breadcrumbs,
  Typography,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import { Link } from '../../atoms';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { SelectField } from '../../atoms';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  rootSelect: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  menuItem: {
    fontWeight: 'bold',
  },
}));

const SubHeader = (props) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { i18n } = useTranslation();

  const [pathName, setPathName] = useState('');
  const [locale, setLocale] = useState(i18n.language);
  const { subNavList } = props;

  useEffect(() => {
    if (location.pathname) {
      setPathName(location.pathname);
    }
  }, [location]);

  const handelSubNavClick = useCallback(
    (event, link) => {
      event.preventDefault();
      history.push(link);
    },
    [history]
  );

  const changeLanguage = (lng) => {
    setLocale(lng);
    i18n.changeLanguage(lng);
  };

  const navList = subNavList?.find((list) => list.route === pathName);
  const nav = navList?.nav
    ? navList.nav
    : navList?.breadCrumb
    ? navList.breadCrumb
    : [];

  const BreadCrumb = (
    <Breadcrumbs
      separator={
        navList?.breadCrumb ? <NavigateNextIcon fontSize='small' /> : ''
      }
      aria-label='breadcrumb'
      className={`sub-nav-container`}>
      {nav.map((item) => {
        let cssClass = 'sub-nav-list';
        if (
          pathName === item.link ||
          (pathName === '/' + UrlText.WELCOME_PAGE.toLocaleLowerCase() &&
            item.text === UrlText.ACCOUNT_SUMMARY)
        ) {
          cssClass = cssClass + ' active';
        }
        return (
          <Link
            key={item.link}
            color='primary'
            href={item.link}
            onClick={(e) => handelSubNavClick(e, item.link)}
            className={cssClass}>
            {item.text}
          </Link>
        );
      })}
    </Breadcrumbs>
  );

  return (
    <div className={`sub-header ${props.className}`} style={props.style}>
      <div>{nav.length > 0 && BreadCrumb}</div>
      <SelectField
        value={locale}
        onChange={(value) => changeLanguage(value)}
        disableUnderline
        classes={{ select: classes.rootSelect }}
        IconComponent={KeyboardArrowDownIcon}>
        {i18nLanguages.map((lang) => {
          return (
            <MenuItem key={lang.value} value={lang.value}>
              <Typography
                color='textSecondary'
                variant='subtitle2'
                className={classes.menuItem}>
                {lang.text}
              </Typography>
            </MenuItem>
          );
        })}
      </SelectField>
    </div>
  );
};

export default SubHeader;
