import {
  Grid,
  Typography,
  Paper,
  makeStyles,
  Divider,
} from '@material-ui/core';
import { formatAmount, formatDate } from '../../../utils/utils';
import { Routes, UrlText, limitsAndFees } from '../../../utils/constant';
import { WatchLater } from '@material-ui/icons/';
import { Link, LinearProgress } from '../../atoms';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  boldText: {
    fontWeight: 'bold',
  },
  limitsContainer: {
    backgroundColor: theme.palette.secondary.main,
  },
  containerPadding: {
    padding: theme.spacing(2),
  },
}));

const LimitsAndFees = (props) => {
  const classes = useStyles();
  const { nextBillingDate, totalFee, feesAndLimits } = props;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Typography variant='h4' component='h1'>
                {UrlText.LIMITS_AND_FEES}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems='center' spacing={1}>
                <Grid item>
                  <WatchLater fontSize={'small'} />
                </Grid>
                <Grid item>
                  <Typography variant='body1'>
                    {nextBillingDate && formatDate(nextBillingDate)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent='flex-end'>
                <Typography variant='subtitle2'>Payment date</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.container} elevation={0}>
            <Typography variant='body1' className={classes.boldText}>
              MONTHLY PAYMENTS & LIMITS
            </Typography>
            <Typography>
              {totalFee && formatAmount(totalFee.amount, totalFee.currency)}{' '}
              monthly
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant='body1'>
                All limits & fees for your account
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {feesAndLimits.map((item) => {
                  const {
                    code,
                    countConsumption,
                    countLimit,
                    amountConsumption,
                    amountLimit,
                    extraPercentageCharge,
                    extraAbsoluteCharge,
                  } = item;
                  let feesText = `Fee ${extraPercentageCharge}% per withdrawal`;

                  let percentage = 0;
                  if (countLimit) {
                    percentage = (countConsumption / countLimit) * 100;
                  } else if (amountLimit) {
                    percentage =
                      Number(amountLimit.amount) === 0
                        ? 0
                        : (Number(amountConsumption.amount) /
                            Number(amountLimit.amount)) *
                          100;
                  }

                  if (countLimit && countLimit - countConsumption !== 0) {
                    feesText = `${countConsumption} used out of ${countLimit}`;
                  } else if (amountLimit && Number(amountLimit?.amount) !== 0) {
                    if (
                      Number(amountLimit?.amount) -
                        Number(amountConsumption?.amount) !==
                      0
                    ) {
                      feesText = `${formatAmount(
                        amountConsumption?.amount,
                        amountConsumption?.currency,
                        0
                      )} used out of ${formatAmount(
                        amountLimit?.amount,
                        amountLimit?.currency,
                        0
                      )}`;
                    }
                  } else if (extraAbsoluteCharge) {
                    feesText = `Fee ${formatAmount(
                      extraAbsoluteCharge.amount,
                      extraAbsoluteCharge.currency,
                      0
                    )} per transaction`;
                  }

                  return (
                    <Grid item key={code} xs={6}>
                      <Link
                        href={`${Routes.LIMITS_AND_FEES_INFO}?code=${code}`}>
                        <Paper
                          elevation={0}
                          className={classes.limitsContainer}>
                          <Grid
                            container
                            spacing={1}
                            className={classes.containerPadding}>
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                className={classes.boldText}>
                                {limitsAndFees[code]}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant='subtitle2'>
                                {feesText}
                              </Typography>
                              <LinearProgress
                                variant='determinate'
                                value={percentage}
                              />
                            </Grid>
                          </Grid>
                          <Divider />
                          <Grid container className={classes.containerPadding}>
                            <Grid item xs={12}>
                              <Typography variant='subtitle2'>
                                {code === 'SWI' || code === 'FEX'
                                  ? 'Increased with Made 2'
                                  : 'Standard Limit'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LimitsAndFees;
