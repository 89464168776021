import { Routes, UrlText } from './constant';

const accountsSubNav = [
  {
    link: Routes.ACCOUNT_SUMMARY,
    text: UrlText.ACCOUNT_SUMMARY,
  },
  {
    link: Routes.TRANSACTION_HISTORY,
    text: UrlText.TRANSACTION_HISTORY,
  },
  {
    link: Routes.ACCOUNT_INFO,
    text: UrlText.ACCOUNT_INFO,
  },
  {
    link: Routes.BENEFICIARY_MGMT,
    text: UrlText.BENEFICIARY_MGMT,
  },
];

const accountsViewBreadCrumb = [
  {
    link: Routes.ACCOUNT_SUMMARY,
    text: UrlText.ACCOUNT_SUMMARY,
  },
  {
    link: Routes.ACCOUNT_VIEW,
    text: UrlText.ACCOUNT_VIEW,
  },
];

const cardDetailsBreadCrumb = [
  {
    link: Routes.CARDS_PAGE,
    text: UrlText.CARDS_PAGE,
  },
  {
    link: Routes.CARD_DETAILS,
    text: UrlText.CARD_DETAILS,
  },
];

const cardsSubNav = [
  {
    link: Routes.CARDS_PAGE,
    text: UrlText.CARDS_PAGE,
  },
  {
    link: Routes.CARD_PLANS,
    text: UrlText.CARD_PLANS,
  },
];

const cardPlanInfoBreadCrumb = [
  {
    link: Routes.CARD_PLANS,
    text: UrlText.CARD_PLANS,
  },
  {
    link: Routes.CARD_PLAN_INFO,
    text: UrlText.CARD_PLAN_INFO,
  },
];

export const subNavList = [
  {
    route: Routes.WELCOME_PAGE,
    nav: accountsSubNav,
  },
  {
    route: Routes.ACCOUNT_SUMMARY,
    nav: accountsSubNav,
  },
  {
    route: Routes.TRANSACTION_HISTORY,
    nav: accountsSubNav,
  },
  {
    route: Routes.ACCOUNT_INFO,
    nav: accountsSubNav,
  },
  {
    route: Routes.ACCOUNT_VIEW,
    breadCrumb: accountsViewBreadCrumb,
  },
  {
    route: Routes.BENEFICIARY_MGMT,
    nav: accountsSubNav,
  },
  {
    route: Routes.CARD_DETAILS,
    breadCrumb: cardDetailsBreadCrumb,
  },
  {
    route: Routes.CARDS_PAGE,
    nav: cardsSubNav,
  },
  {
    route: Routes.CARD_PLANS,
    nav: cardsSubNav,
  },
  {
    route: Routes.CARD_PLAN_INFO,
    breadCrumb: cardPlanInfoBreadCrumb,
  },
];
