import * as actionType from './actionType';

export const getCardPlans = () => {
  return {
    type: actionType.GET_CARD_PLANS,
  };
};

export const getCardPlansSuccess = (data) => {
  return {
    type: actionType.GET_CARD_PLANS_SUCCESS,
    data,
  };
};

export const getCardPlansFailed = (error) => {
  return {
    type: actionType.GET_CARD_PLANS_FAILED,
    error,
  };
};

export const getUserPlans = () => {
  return {
    type: actionType.GET_USER_PLANS,
  };
};

export const getUserPlansSuccess = (data) => {
  return {
    type: actionType.GET_USER_PLANS_SUCCESS,
    data,
  };
};

export const getUserPlansFailed = (error) => {
  return {
    type: actionType.GET_USER_PLANS_FAILED,
    error,
  };
};
