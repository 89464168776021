import React from 'react';
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
} from '@material-ui/core';
import { PriorityHigh } from '@material-ui/icons';
import { formatDate } from '../../../utils/utils';

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    backgroundColor: theme.palette.secondary.main,
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
}));

const Notification = (props) => {
  const classes = useStyles();
  const { notifications } = props;

  return (
    <>
      {notifications.map((data) => {
        return (
          <React.Fragment key={data.createdAt}>
            <Typography variant='body1'>
              {formatDate(data.createdAt, 'DD MMMM', true)}
            </Typography>
            <List
              component='nav'
              className={classes.rootList}
              aria-label='notifications'>
              {data.notifications.map((item) => {
                return (
                  <ListItem key={item.id} className={classes.listItem}>
                    <ListItemAvatar>
                      <Avatar>
                        <PriorityHigh />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.title} secondary={item.body} />
                  </ListItem>
                );
              })}
            </List>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Notification;
