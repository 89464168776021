import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import translationEnGb from './locales/enGb/translation.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    resources: {
      enGb: {
        translation: translationEnGb,
      },
    },

    debug: process.env.NODE_ENV === 'development' ? true : false,
    lng: 'enGb',
    fallbackLng: 'enGb',

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },

    ns: ['translation'],
    defaultNS: 'translation',

    react: {
      useSuspense: false,
    },
  });

export default i18n;
