import * as actionType from './actionType';

export const getWcepPointsBalance = () => {
  return {
    type: actionType.GET_WCEP_POINTS_BALANCE,
  };
};

export const getWcepPointsBalanceSuccess = (data) => {
  return {
    type: actionType.GET_WCEP_POINTS_BALANCE_SUCCESS,
    data,
  };
};

export const getWcepPointsBalanceFailed = (error) => {
  return {
    type: actionType.GET_WCEP_POINTS_BALANCE_FAILED,
    error,
  };
};

export const getWcepTasks = () => {
  return {
    type: actionType.GET_WCEP_TASKS,
  };
};

export const getWcepTasksSuccess = (data) => {
  return {
    type: actionType.GET_WCEP_TASKS_SUCCESS,
    data,
  };
};

export const getWcepTasksFailed = (error) => {
  return {
    type: actionType.GET_WCEP_TASKS,
    error,
  };
};

export const getRewardsHistory = () => {
  return {
    type: actionType.GET_REWARDS_HISTORY,
  };
};

export const getRewardsHistorySuccess = (data) => {
  return {
    type: actionType.GET_REWARDS_HISTORY_SUCCESS,
    data,
  };
};

export const getRewardsHistoryFailed = (error) => {
  return {
    type: actionType.GET_REWARDS_HISTORY_FAILED,
    error,
  };
};
