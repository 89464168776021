import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  accountFetchAllStart,
  accountFetchAllFailed,
} from '../../../store/actions/index';
import { Loader } from '../../atoms';
import classes from './TransactionHistory.module.scss';
import { Transaction } from '../../organisms';

const TransactionHistory = (props) => {
  const { list } = props;

  useEffect(() => {
    if (!props.list.length && !props.loading) {
      props.accountFetchAllStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.accountFetchAllFailed, props.list]);

  if (props.loading) return <Loader />;

  return (
    <div className={classes.main}>
      <Transaction list={list} showFromDate showToDate />
    </div>
  );
};

const mapStateToProps = (state) => {
  const account = state.account;
  return {
    loading: account.loading,
    list: account.list,
  };
};

const mapDispatchToProps = {
  accountFetchAllStart,
  accountFetchAllFailed,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory);
