import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const Dot = (props) => {
  const useStyles = makeStyles((theme) => ({
    dot: {
      height: '12px',
      width: '12px',
      borderRadius: '50%',
      display: 'inline-block',
      verticalAlign: 'middle',
      backgroundColor: props.status === 'OPEN' ? '#5cc700' : '#ed2a11',
    },
  }));

  const classes = useStyles();

  return (
    <Typography
      {...props}
      component='span'
      className={`${classes.dot} ${props.className}`}
      style={props.style}></Typography>
  );
};

export default Dot;
