export const ACCOUNT_ALL_START = 'ACCOUNT_ALL_START';
export const ACCOUNT_ALL_SUCCESS = 'ACCOUNT_ALL_SUCCESS';
export const ACCOUNT_ALL_FAILED = 'ACCOUNT_ALL_FAILED';

export const ACCOUNT_START = 'ACCOUNT_START';
export const ACCOUNT_SUCCESS = 'ACCOUNT_SUCCESS';
export const ACCOUNT_FAILED = 'ACCOUNT_FAILED';

// For Customer API
export const CUSTOMER_START = 'CUSTOMER_START';
export const CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS';
export const CUSTOMER_FAILED = 'CUSTOMER_FAILED';

export const GET_ALL_TRANS = 'GET_ALL_TRANS';
export const GET_ALL_TRANS_SUCCESS = 'GET_ALL_TRANS_SUCCESS';
export const GET_ALL_TRANS_FAILED = 'GET_ALL_TRANS_FAILED';

export const GET_CARDS = 'GET_CARDS';
export const GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS';
export const GET_CARDS_FAILED = 'GET_CARDS_FAILED';

export const GET_CARD_DETAILS = 'GET_CARD_DETAILS';
export const GET_CARD_DETAILS_SUCCESS = 'GET_CARD_DETAILS_SUCCESS';
export const GET_CARD_DETAILS_FAILED = 'GET_CARD_DETAILS_FAILED';

export const GET_CARD_SECURED_DETAILS = 'GET_CARD_SECURED_DETAILS';
export const GET_CARD_SECURED_DETAILS_SUCCESS =
  'GET_CARD_SECURED_DETAILS_SUCCESS';
export const GET_CARD_SECURED_DETAILS_FAILED =
  'GET_CARD_SECURED_DETAILS_FAILED';

export const GET_BENEFICIARIES = 'GET_BENEFICIARIES';
export const GET_BENEFICIARIES_SUCCESS = 'GET_BENEFICIARIES_SUCCESS';
export const GET_BENEFICIARIES_FAILED = 'GET_BENEFICIARIES_FAILED';

export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
export const GET_ALL_NOTIFICATIONS_SUCCESS = 'GET_ALL_NOTIFICATIONS_SUCCESS';
export const GET_ALL_NOTIFICATIONS_FAILED = 'GET_ALL_NOTIFICATIONS_FAILED';

export const GET_WCEP_POINTS_BALANCE = 'GET_WCEP_POINTS_BALANCE';
export const GET_WCEP_POINTS_BALANCE_SUCCESS =
  'GET_WCEP_POINTS_BALANCE_SUCCESS';
export const GET_WCEP_POINTS_BALANCE_FAILED = 'GET_WCEP_POINTS_BALANCE_FAILED';

export const GET_WCEP_TASKS = 'GET_WCEP_TASKS';
export const GET_WCEP_TASKS_SUCCESS = 'GET_WCEP_TASKS_SUCCESS';
export const GET_WCEP_TASKS_FAILED = 'GET_WCEP_TASKS_FAILED';

export const GET_REWARDS_HISTORY = 'GET_REWARDS_HISTORY';
export const GET_REWARDS_HISTORY_SUCCESS = 'GET_REWARDS_HISTORY_SUCCESS';
export const GET_REWARDS_HISTORY_FAILED = 'GET_REWARDS_HISTORY_FAILED';

export const GET_CARD_PLANS = 'GET_CARD_PLANS';
export const GET_CARD_PLANS_SUCCESS = 'GET_CARD_PLANS_SUCCESS';
export const GET_CARD_PLANS_FAILED = 'GET_CARD_PLANS_FAILED';

export const GET_USER_PLANS = 'GET_USER_PLANS';
export const GET_USER_PLANS_SUCCESS = 'GET_USER_PLANS_SUCCESS';
export const GET_USER_PLANS_FAILED = 'GET_USER_PLANS_FAILED';

export const GET_LIMITS_AND_FEES = 'GET_LIMITS_AND_FEES';
export const GET_LIMITS_AND_FEES_SUCCESS = 'GET_LIMITS_AND_FEES_SUCCESS';
export const GET_LIMITS_AND_FEES_FAILED = 'GET_LIMITS_AND_FEES_FAILED';
