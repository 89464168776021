import * as actionType from './actionType';

export const getLimitsAndFees = () => {
  return {
    type: actionType.GET_LIMITS_AND_FEES,
  };
};

export const getLimitsAndFeesSuccess = (data) => {
  return {
    type: actionType.GET_LIMITS_AND_FEES_SUCCESS,
    data,
  };
};

export const getLimitsAndFeesFailed = (error) => {
  return {
    type: actionType.GET_LIMITS_AND_FEES_FAILED,
    error,
  };
};
