import {
  GET_BENEFICIARIES,
  GET_BENEFICIARIES_SUCCESS,
  GET_BENEFICIARIES_FAILED,
} from '../actions/actionType';
import { updateObject } from '../../utils/utils';

const initialState = {
  loading: false,
  error: false,
  errorMassage: null,
  data: [],
};

const getBeneficiaries = (state) => {
  return updateObject(state, {
    loading: true,
    error: false,
  });
};

const getBeneficiariesSuccess = (state, action) => {
  const data = action.data;
  return updateObject(state, {
    loading: true,
    error: false,
    data: [...data],
  });
};

const getBeneficiariesFailed = (state, action) => {
  const {
    error: {
      response: { data },
    },
  } = action;
  return updateObject(state, {
    loading: false,
    error: true,
    errorMassage: data.message,
    ...data,
  });
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_BENEFICIARIES:
      return getBeneficiaries(state);
    case GET_BENEFICIARIES_SUCCESS:
      return getBeneficiariesSuccess(state, action);
    case GET_BENEFICIARIES_FAILED:
      return getBeneficiariesFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
