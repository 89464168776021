import * as actionType from '../actions/actionType';
import { updateObject } from '../../utils/utils';

const initialState = {
  loading: false,
  error: false,
  errorMessage: null,
  data: [],
};

const getLimitsAndFees = (state) => {
  return updateObject(state, {
    loading: true,
    error: false,
    errorMessage: null,
  });
};

const getLimitsAndFeesSuccess = (state, action) => {
  const data = action.data;
  return updateObject(state, {
    loading: false,
    data: [...data],
  });
};

export const getError = (state, action) => {
  const {
    error: {
      response: { data },
    },
  } = action;
  return updateObject(state, {
    loading: false,
    error: true,
    errorMessage: data.message,
    ...data,
  });
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionType.GET_LIMITS_AND_FEES:
      return getLimitsAndFees(state, action);
    case actionType.GET_LIMITS_AND_FEES_SUCCESS:
      return getLimitsAndFeesSuccess(state, action);
    case actionType.GET_LIMITS_AND_FEES_FAILED:
      return getError(state, action);
    default:
      return state;
  }
};

export default reducer;
