import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card } from '../../organisms';
import { getCardDetails, getCardSecuredDetails } from '../../../store/actions';
import { Loader } from '../../atoms';
import classes from './CardDetails.module.scss';
import { parseParams } from '../../../utils/utils';

const CardDetails = (props) => {
  const {
    loading,
    cardDetails,
    cardSecuredDetails,
    getCardDetails,
    getCardSecuredDetails,
  } = props;
  const { cardId } = parseParams(window.location.search);

  useEffect(() => {
    if (cardId) {
      getCardDetails(cardId);
    }
  }, [getCardDetails, cardId]);

  useEffect(() => {
    if (cardId) {
      getCardSecuredDetails(cardId);
    }
  }, [getCardSecuredDetails, cardId]);

  const {
    cardStyle: { imgLink, cardType },
    issueFirstName,
    issueLastName,
    status,
    ...otherItems
  } = cardDetails;

  const { cardNumber } = cardSecuredDetails;
  const cardName = `${issueFirstName} ${issueLastName}`;

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Card
        key={cardId}
        imgLink={imgLink}
        cardName={cardName}
        cardNumber={cardNumber}
        cardType={cardType}
        status={status}
        paper={classes.paper}
        imageContainer={classes.imageContainer}
        {...otherItems}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { cards } = state;
  return {
    loading: cards.loading,
    cardDetails: cards.cardDetails,
    cardSecuredDetails: cards.cardSecuredDetails,
  };
};

const mapDispatchToProps = {
  getCardDetails,
  getCardSecuredDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardDetails);
