import React from 'react';
import { Typography, Button, makeStyles, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const buttonStyle = {
  padding: '15px',
  minWidth: '250px',
  borderRadius: '40px',
};

const useStyles = makeStyles((theme) => ({
  Typography: {
    fontWeight: 'bold',
  },
  button: {
    ...buttonStyle,
    marginTop: theme.spacing(6),
  },
  resendBtn: {
    ...buttonStyle,
    marginTop: theme.spacing(2),
    textTransform: 'none',
  },
  link: {
    color: '#0778f6',
    fontSize: '16px',
  },
}));

const Otp = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <section className='login__otp'>
      <Typography className={classes.Typography} variant='body2'>
        {t('enterOTP')}
      </Typography>
      <form
        method='get'
        className='digit-group'
        data-group-name='digits'
        data-autosubmit='false'
        autoComplete='off'>
        <input
          type='text'
          id='otp1'
          maxLength='1'
          name='digit-1'
          onKeyUp={(e) => props.onKeyUpEvent(1, e)}
          onFocus={() => props.onFocusEvent(1)}
        />
        <input
          type='text'
          id='otp2'
          maxLength='1'
          name='digit-2'
          onKeyUp={(e) => props.onKeyUpEvent(2, e)}
          onFocus={() => props.onFocusEvent(2)}
        />
        <input
          type='text'
          id='otp3'
          maxLength='1'
          name='digit-3'
          onKeyUp={(e) => props.onKeyUpEvent(3, e)}
          onFocus={() => props.onFocusEvent(3)}
        />
        <input
          type='text'
          id='otp4'
          maxLength='1'
          name='digit-4'
          onKeyUp={(e) => props.onKeyUpEvent(4, e)}
          onFocus={() => props.onFocusEvent(4)}
        />
      </form>
      <Button
        variant='contained'
        color='primary'
        className={classes.button}
        onClick={props.verifyOtp}
        disabled={props.isVerifyOtpDisabled}>
        DONE
      </Button>
      <Button className={classes.resendBtn}>
        <Link
          underline={'none'}
          className={classes.link}
          onClick={props.handelSubmit}>
          Resend
        </Link>
      </Button>
    </section>
  );
};

export default Otp;
