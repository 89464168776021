import * as actionType from "./actionType";

export const customerFetchStart = () => {
    return {
        type: actionType.CUSTOMER_START
    }
}

export const customerFetchSuccess = (data) => {
    return {
        data,
        type: actionType.CUSTOMER_SUCCESS
    }
}

export const customerFetchFailed = (error) => {
    return {
        error,
        type: actionType.CUSTOMER_FAILED
    }
}