import * as actionType from '../actions/actionType';
import { updateObject } from '../../utils/utils';

const initialState = {
  loading: false,
  error: false,
  errorMessage: null,
  data: [],
  cardDetails: {
    cardId: null,
    cardNumberLastFourDigits: null,
    issueFirstName: null,
    issueLastName: null,
    status: null,
    cardStyle: {
      cardType: null,
      imgLink: null,
    },
  },
  cardSecuredDetails: {
    cardNumber: null,
  },
};

export const getError = (state, action) => {
  const {
    error: {
      response: { data },
    },
  } = action;
  return updateObject(state, {
    loading: false,
    error: true,
    errorMessage: data.message,
    ...data,
  });
};

const getCards = (state) => {
  return updateObject(state, {
    loading: true,
    error: false,
    errorMessage: null,
    data: [],
  });
};

const getCardsSuccess = (state, action) => {
  const data = action.data;
  return updateObject(state, {
    loading: false,
    data: [...data],
  });
};

const getCardDetails = (state) => {
  return updateObject(state, {
    loading: true,
    error: false,
    errorMessage: null,
  });
};

const getCardDetailsSuccess = (state, action) => {
  const data = action.data;
  return updateObject(state, {
    loading: false,
    cardDetails: { ...data },
  });
};

const getCardSecuredDetails = (state) => {
  return updateObject(state, {
    loading: true,
    error: false,
    errorMessage: null,
  });
};

const getCardSecuredDetailsSuccess = (state, action) => {
  const data = action.data;
  return updateObject(state, {
    loading: false,
    cardSecuredDetails: { ...data },
  });
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionType.GET_CARDS:
      return getCards(state);
    case actionType.GET_CARDS_SUCCESS:
      return getCardsSuccess(state, action);
    case actionType.GET_CARDS_FAILED:
      return getError(state, action);
    case actionType.GET_CARD_DETAILS:
      return getCardDetails(state);
    case actionType.GET_CARD_DETAILS_SUCCESS:
      return getCardDetailsSuccess(state, action);
    case actionType.GET_CARD_DETAILS_FAILED:
      return getError(state, action);
    case actionType.GET_CARD_SECURED_DETAILS:
      return getCardSecuredDetails(state, action);
    case actionType.GET_CARD_SECURED_DETAILS_SUCCESS:
      return getCardSecuredDetailsSuccess(state, action);
    case actionType.GET_CARD_SECURED_DETAILS_FAILED:
      return getError(state, action);
    default:
      return state;
  }
};

export default reducer;
