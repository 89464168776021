import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { accountFetchAllStart } from '../../../store/actions/index';
import Account from '../../organisms/Account/Account';
import { Loader } from '../../atoms';

const AccountInfo = (props) => {
  const { list, loading, accountFetchAllStart } = props;

  useEffect(() => {
    if (!list.length && !loading) {
      accountFetchAllStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountFetchAllStart]);

  return (
    <>
      {list.map((item) => {
        const {
          accountId,
          accountType,
          currencyCode,
          accNumber,
          balance,
          bic,
          customerFullName,
          iban,
        } = item;

        return (
          <Account
            key={accountId}
            accountType={accountType}
            currencyCode={currencyCode}
            accNumber={accNumber}
            balance={balance}
            bic={bic}
            customerFullName={customerFullName}
            iban={iban}
          />
        );
      })}
      {loading && <Loader />}
    </>
  );
};

const mapStateToProps = (state) => {
  const { account } = state;
  return {
    loading: account.loading,
    list: account.list,
  };
};

const mapDispatchToProps = {
  accountFetchAllStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
