import * as actionType from '../actions/actionType';
import { updateObject } from '../../utils/utils';

const initialState = {
  loading: false,
  error: false,
  errorMessage: null,
  points_balance: 0,
  tasks: [],
  rewards_history: [],
};

export const getError = (state, action) => {
  const {
    error: {
      response: { data },
    },
  } = action;
  return updateObject(state, {
    loading: false,
    error: true,
    errorMessage: data.message,
    ...data,
  });
};

const getPointsBalance = (state) => {
  return updateObject(state, {
    loading: true,
    error: false,
    errorMessage: null,
    points_balance: 0,
  });
};

const getPointsBalanceSuccess = (state, action) => {
  const data = action.data;
  return updateObject(state, {
    loading: false,
    points_balance: data.wittyPoints,
  });
};

const getTasks = (state) => {
  return updateObject(state, {
    loading: true,
    error: false,
    errorMessage: null,
    tasks: [],
  });
};

const getTasksSuccess = (state, action) => {
  const data = action.data;
  return updateObject(state, {
    loading: false,
    tasks: [...data],
  });
};

const getRewardsHistory = (state) => {
  return updateObject(state, {
    loading: true,
    error: false,
    errorMessage: null,
    rewards_history: [],
  });
};

const getRewardsHistorySuccess = (state, action) => {
  const data = action.data;
  return updateObject(state, {
    loading: false,
    rewards_history: [...data],
  });
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionType.GET_WCEP_POINTS_BALANCE:
      return getPointsBalance(state);
    case actionType.GET_WCEP_POINTS_BALANCE_SUCCESS:
      return getPointsBalanceSuccess(state, action);
    case actionType.GET_WCEP_POINTS_BALANCE_FAILED:
      return getError(state, action);
    case actionType.GET_WCEP_TASKS:
      return getTasks(state);
    case actionType.GET_WCEP_TASKS_SUCCESS:
      return getTasksSuccess(state, action);
    case actionType.GET_WCEP_TASKS_FAILED:
      return getError(state, action);
    case actionType.GET_REWARDS_HISTORY:
      return getRewardsHistory(state);
    case actionType.GET_REWARDS_HISTORY_SUCCESS:
      return getRewardsHistorySuccess(state, action);
    case actionType.GET_REWARDS_HISTORY_FAILED:
      return getError(state, action);
    default:
      return state;
  }
};

export default reducer;
