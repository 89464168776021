import * as actions from '../actions';
import { put } from '@redux-saga/core/effects';
import { http, interpolateUrl } from '../../utils/utils';
import { Constants } from '../../utils/constant';

export function* getCardsSaga(action) {
  try {
    const { payload } = action;
    const url = interpolateUrl(Constants.API.CARDS, payload);
    const response = yield http({
      method: 'GET',
      url,
    });
    yield put(actions.getCardsSuccess(response.data));
  } catch (error) {
    yield put(actions.getCardsFailed(error));
  }
}

export function* getCardDetailsSaga(action) {
  try {
    const { payload } = action;
    const url = interpolateUrl(Constants.API.CARD_DETAILS, payload);
    const response = yield http({
      method: 'GET',
      url,
    });
    yield put(actions.getCardDetailsSuccess(response.data));
  } catch (error) {
    yield put(actions.getCardDetailsFailed(error));
  }
}

export function* getCardSecuredDetailsSaga(action) {
  try {
    const { payload } = action;
    const url = interpolateUrl(Constants.API.CARD_SECURED_DETAILS, payload);
    const response = yield http({
      method: 'GET',
      url,
    });
    yield put(actions.getCardSecuredDetailsSuccess(response.data));
  } catch (error) {
    yield put(actions.getCardSecuredDetailsFailed(error));
  }
}
