import * as actions from '../actions/index';
import { put } from 'redux-saga/effects';
import { http } from '../../utils/utils';
import { Constants } from '../../utils/constant';

export function* getCardPlansSaga() {
  try {
    const response = yield http({
      method: 'GET',
      url: Constants.API.CARD_PLANS,
    });

    yield put(actions.getCardPlansSuccess(response.data));
  } catch (error) {
    yield put(actions.getCardPlansFailed(error));
  }
}

export function* getUserPlansSaga() {
  try {
    const response = yield http({
      method: 'GET',
      url: Constants.API.USER_PLANS,
    });

    yield put(actions.getUserPlansSuccess(response.data));
  } catch (error) {
    yield put(actions.getUserPlansFailed(error));
  }
}
