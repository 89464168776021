import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
// import classes from './DashboardStyle.module.scss';
import AccountSummary from '../../organisms/AccountSummary/AccountSummary';
import { Loader } from '../../atoms';

import {
  accountFetchAllStart,
  accountFetchAllSuccess,
  accountFetchAllFailed,
} from '../../../store/actions/index';

const Dashboard = (props) => {
  useEffect(() => {
    if (!props.list.length && !props.loading) {
      props.accountFetchAllStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.accountFetchAllFailed, props.list]);
  return (
    <>
      {!props.loading && (
        <div className={null}>
          <Typography variant='h4' align='left'>
            Hi {props.firstName}!
          </Typography>
          <Typography variant='body1'>Below are your accounts</Typography>
          {props.list.map((item) => {
            return (
              <AccountSummary
                key={item.accountId}
                currencyCode={item.currencyCode}
                accountNum={item.accNumber}
                balance={item.balance}
                accountId={item.accountId}
              />
            );
          })}
        </div>
      )}
      {props.loading && <Loader />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.account.loading,
    firstName: state.customer?.customerProfileDto?.firstName,
    list: state.account.list,
    error: state.account.error,
    errorMassage: state.account.errorMassage,
  };
};

const mapDispatchToProps = {
  accountFetchAllStart,
  accountFetchAllSuccess,
  accountFetchAllFailed,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
