import { combineReducers } from 'redux';
import account from './account';
import beneficiaries from './beneficiaries';
import cards from './cards';
import customer from './customer';
import limits from './limits';
import notifications from './notifications';
import plans from './plans';
import transaction from './transaction';
import wittyRewards from './wittyRewards';

export default combineReducers({
  account,
  beneficiaries,
  cards,
  customer,
  limits,
  notifications,
  plans,
  transaction,
  wittyRewards,
});
