import {
  makeStyles,
  LinearProgress as MUILinearProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 400 : 700],
  },
  bar: {
    borderRadius: 5,
  },
}));

const LinearProgress = (props) => {
  const classes = useStyles();

  return <MUILinearProgress classes={classes} {...props} />;
};

export default LinearProgress;
