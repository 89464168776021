import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDataGrid-columnHeaderWrapper': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    '& .MuiDataGrid-cell': {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      borderBottom: 'none',
    },
    '& .MuiDataGrid-row:nth-child(2n)': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const DataTable = (props) => {
  const muiClasses = useStyles();

  return (
    <>
      <DataGrid
        {...props}
        rows={props.rows}
        columns={props.columns}
        classes={{
          root: `${muiClasses.root} ${props.classes.root}`,
        }}
      />
    </>
  );
};

export default DataTable;
