import {
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATIONS_FAILED,
} from '../actions/actionType';
import { updateObject } from '../../utils/utils';

const initialState = {
  loading: false,
  error: false,
  data: [],
};

export const getError = (state, action) => {
  const {
    error: {
      response: { data },
    },
  } = action;
  return updateObject(state, {
    loading: false,
    error: true,
    errorMessage: data.message,
    ...data,
  });
};

const getAllNotifications = (state) => {
  return updateObject(state, {
    loading: true,
    error: false,
    errorMessage: null,
    data: [],
  });
};

const getAllNotificationsSuccess = (state, action) => {
  const data = action.data;
  return updateObject(state, {
    loading: false,
    data: [...data],
  });
};

const getAllNotificationsFailed = (state, action) => {
  const data = action.data;
  return updateObject(state, {
    loading: false,
    error: true,
    ...data,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_NOTIFICATIONS:
      return getAllNotifications(state, action);
    case GET_ALL_NOTIFICATIONS_SUCCESS:
      return getAllNotificationsSuccess(state, action);
    case GET_ALL_NOTIFICATIONS_FAILED:
      return getAllNotificationsFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
