import * as actions from '../actions/index';
import { put } from 'redux-saga/effects';
import { http } from '../../utils/utils';
import { Constants } from '../../utils/constant';

export function* getPointsBalanceSaga() {
  try {
    const response = yield http({
      method: 'GET',
      url: Constants.API.WCEP_POINTS,
    });

    yield put(actions.getWcepPointsBalanceSuccess(response.data));
  } catch (error) {
    yield put(actions.getWcepPointsBalanceFailed(error));
  }
}

export function* getTasksSaga() {
  try {
    const response = yield http({
      method: 'GET',
      url: Constants.API.WCEP_TASKS,
    });
    yield put(actions.getWcepTasksSuccess(response.data));
  } catch (error) {
    yield put(actions.getWcepTasksFailed(error));
  }
}

export function* getRewardsHistorySaga() {
  try {
    const response = yield http({
      method: 'GET',
      url: Constants.API.REWARDS_HISTORY,
    });
    yield put(actions.getRewardsHistorySuccess(response.data));
  } catch (error) {
    yield put(actions.getRewardsHistoryFailed(error));
  }
}
