import axios from 'axios';
import moment from 'moment';
import { membershipPlan, Routes } from './constant';
import CurrencyList from 'currency-list';
const { REACT_APP_BE_URL, REACT_APP_BENEFICIARY_MGMT_URL } = process.env;

export const setHeader = (token) => {
  axios.defaults.headers.common = { 'X-Access-Token': `${token}` };
};
export function http(obj) {
  let response = null;
  const url = getFullURL(obj.url, obj.domain);
  const token = getCookie('key');
  if (token) {
    setHeader(token);
  } else {
    Logout();
  }
  try {
    if (obj.method === 'get' || obj.method === 'GET') {
      response = axios.get(url, { params: obj.params });
    } else {
      response = axios.post(url);
    }
  } catch (error) {
    response = error;
  }
  return response;
}
export const setCookie = (name, value, time) => {
  var expires = '';
  if (time) {
    var date = new Date();
    date.setTime(date.getTime() + time);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};
export const getCookie = (name) => {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
export const eraseCookie = (name) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const Logout = () => {
  window.Intercom('shutdown');
  eraseCookie('key');
  window.location.href = Routes.LOGIN;
};

export const validEmailRegex = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
); //eslint-disable-line

export const updateObject = (oldObj, updatedProperties) => {
  return {
    ...oldObj,
    ...updatedProperties,
  };
};

export const formatAccountNum = (num) => {
  return num ? num.toString().replace(/\d{4}(?=.)/g, '$& ') : '';
};

export const formatDate = (date, format = 'DD MMMM', dateDiff = false) => {
  const today = moment().startOf('day');
  const givenDate = moment(date).startOf('day');

  if (dateDiff) {
    const dateDifference = givenDate.diff(today, 'days', true);
    if (dateDifference === 0) {
      return 'Today';
    } else if (dateDifference === -1) {
      return 'Yesterday';
    } else if (dateDifference === 1) {
      return 'Tomorrow';
    } else {
      return moment(date).format(format);
    }
  } else {
    return moment(date).format(format);
  }
};

export const formatTime = (date) => {
  const getDate = new Date(date);
  const time = getDate.toLocaleString('default', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return time;
};

export const formatAmount = (amount, currency_name, scale = 2) => {
  const getAmount = Number(amount).toFixed(scale);
  const currencySymbol = getCurrency(currency_name).symbol;
  return `${currencySymbol} ${getAmount}`;
};

const urlMap = {
  default: REACT_APP_BE_URL,
  beneficiaries: REACT_APP_BENEFICIARY_MGMT_URL,
};

export const getFullURL = (url, key) => {
  return urlMap[key || 'default'] + url;
};

export const parseParams = (querystring) => {
  // parse query string
  const params = new URLSearchParams(querystring);

  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

export const interpolateUrl = (string, values) => {
  return string.replace(/{(.*?)}/g, (match, offset) => values[offset]);
};

export const getCountry = (countryCode) => {
  try {
    let country = new Intl.DisplayNames(['en'], { type: 'region' });
    return country.of(countryCode);
  } catch (error) {}
};

export const getCurrency = (currency_code, locale = 'en_GB') => {
  const currency = CurrencyList.get(currency_code, locale);

  return currency;
};

export const capitalizeTxt = (txt) => {
  return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
};

export const getMembership = (code) => {
  return membershipPlan.find((m) => m.code === code);
};
