import React, { useEffect, useReducer, useRef } from 'react';
import { connect } from 'react-redux';
import {
  MenuItem,
  Typography,
  makeStyles,
  Grid,
  FormControl,
} from '@material-ui/core';
import { SelectField } from '../../atoms';
import { getAllTransactions } from '../../../store/actions/index';
import { DataGrid, TextField } from '../../atoms';
import { formatDate, formatAmount } from '../../../utils/utils';
import classes from './Transaction.module.scss';

const useStyles = makeStyles((theme) => ({
  rootMenuItem: {
    display: 'block',
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    background: 'none',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  rootDataGrid: {
    '& .MuiDataGrid-sortIcon': {
      color: '#fff',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-menuIcon': {
      display: 'none',
    },
    '& .MuiDataGrid-footerContainer': {
      justifyContent: 'center',
    },
  },
}));

const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_ACCOUNT_ID':
      return { ...state, accountId: action.value };
    case 'SET_FROM_DATE':
      return { ...state, fromDate: action.value };
    case 'SET_TO_DATE':
      return { ...state, toDate: action.value };
    default:
      throw new Error();
  }
}

const Transaction = (props) => {
  const muiClasses = useStyles();
  const bottomRef = useRef();

  const {
    list,
    txHistory,
    loading,
    showFromDate,
    showToDate,
    accountId,
    getAllTransactions,
  } = props;

  const initialState = {
    accountId: accountId || null,
    fromDate: null,
    toDate: null,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.accountId !== null) {
      getAllTransactions(state);
    }
  }, [getAllTransactions, state]);

  useEffect(() => {
    scrollToBottom();
  });

  const handleChange = (value, actionType) => {
    dispatch({ type: actionType, value });
  };

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const menuItems = list.map((o) => {
    return (
      <MenuItem
        key={o.accountId}
        value={o.accountId}
        className={classes.menuItem}
        classes={{ root: muiClasses.rootMenuItem }}>
        <Typography className={classes.miTitle}>
          {o.currencyCode} Account
        </Typography>
      </MenuItem>
    );
  });

  const account =
    list.length > 0 &&
    state.accountId &&
    list.find((o) => o.accountId === state.accountId);

  const getDateTime = (params) => {
    const date = formatDate(params.row.dateTime, 'MMMM DD');
    return `${date}`;
  };

  const getAmount = (params) => {
    const currency = params.row.currency;
    const amount = params.row.amount;
    const getAmountWithCurrency = formatAmount(amount, currency);

    return params.row.direction === 'Credit'
      ? `+ ${getAmountWithCurrency}`
      : `- ${getAmountWithCurrency}`;
  };

  const columns = [
    {
      field: 'dateTime',
      headerName: 'Date',
      width: 200,
      valueGetter: getDateTime,
    },
    {
      field: 'narration',
      headerName: 'Narration',
      width: 300,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 200,
      valueGetter: getAmount,
    },
  ];

  const txHistorySorted = txHistory.sort((a, b) =>
    b.dateTime.localeCompare(a.dateTime)
  );

  const rows = txHistorySorted.map((o) => {
    return {
      ...o,
      id: o.transactionSequenceNumber,
    };
  });

  return (
    <div className={classes.main}>
      <Grid container spacing={4}>
        <Grid container item xs={12}>
          <Grid container spacing={2} alignItems='center' item xs={12} lg={8}>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth>
                {list.length > 0 && (
                  <SelectField
                    placeholder={'Select Type of Account'}
                    menuitems={menuItems}
                    className={classes.menuItem}
                    onChange={(value) => handleChange(value, 'SET_ACCOUNT_ID')}
                    MenuProps={MenuProps}
                    variant='outlined'
                    value={state.accountId}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={2} className={classes.balance}>
              {account && (
                <>
                  <Typography className={classes.title} align='left'>
                    Current Balance
                  </Typography>
                  <Typography className={classes.value} align='left'>
                    {formatAmount(account.balance, account.currencyCode)}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              {showFromDate && account && (
                <TextField
                  variant='outlined'
                  name='fromDate'
                  type='date'
                  placeholder='From Date'
                  format='yyyy/MM/dd/'
                  fullWidth
                  onChange={(value) => handleChange(value, 'SET_FROM_DATE')}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              {showToDate && account && (
                <TextField
                  variant='outlined'
                  name='toDate'
                  type='date'
                  placeholder='To Date'
                  format='yyyy/MM/dd/'
                  fullWidth
                  onChange={(value) => handleChange(value, 'SET_TO_DATE')}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {account && (
            <div className={classes.dataTable} ref={bottomRef}>
              <DataGrid
                classes={{
                  root: muiClasses.rootDataGrid,
                }}
                rows={rows}
                columns={columns}
                pageSize={25}
                rowsPerPageOptions={[25]}
                disableSelectionOnClick
                loading={loading}
                pagination
              />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  const transaction = state.transaction;
  return {
    loading: transaction.loading,
    txHistory: transaction.txHistory,
  };
};

const mapDispatchToProps = {
  getAllTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
