import {
  EmojiEmotionsRounded,
  EuroRounded,
  SchoolRounded,
  TimelineRounded,
  CreditCardRounded,
} from '@material-ui/icons';

export const Plans = [
  {
    code: 'MEM01',
    subtitle: 'Free card plan for everyday needs',
    contents: [
      {
        title: 'Access to all W1TTY Features',
        description:
          'With Every1 card you have access to every W1TTY feature and an ability to order more cards later on',
        buttonText: 'Totally Free',
        buttonColor: '#FF5C39',
        buttonTextColor: '#FFF',
        buttonIcon: EmojiEmotionsRounded,
      },
    ],
    benefits: [
      {
        title: 'Fully mobile banking for your transactions',
        description: 'No need to wait for the card, use it right now',
      },
      {
        title: 'Unlimited 0 fee card spendings in EEA',
        description: 'Pay everywhere for anything in Europe',
      },
      {
        title: 'Unlimited receiving in EEA',
        description: 'Receive any SEPA transaction without any  hidden fee',
      },
      {
        title: 'Exchange more than 20 currencies',
        description: 'No charge up to €1,000',
      },
      {
        title: '100% free transactions between your contacts',
        description:
          'Send payments to your friends from contact list who have W1TTY account as well',
      },
      {
        title: 'The best opportunities for your transactions around the world',
        description:
          'Send money to almost all countries and select the payment method which will satisfy your needs',
      },
      {
        title: 'Unlimited virtual cards',
        description:
          'Create and use unlimited number of virtual cards with different currencies',
      },
      {
        title: 'The cheapest premium subscription ever',
        description: 'Pay less and only for things that you truly need',
      },
      {
        title: 'Control your fees & limits anytime',
        description:
          'Check, update your limits and fees or even make them smaller. Everything for your comfort',
      },
      {
        title: 'Unique week goals for everybody',
        description:
          'Achieve your personal goals every week, earn W1TTS and exchange them to real money',
      },
      {
        title: 'Free financial literacy education',
        description:
          'Checkout our huge library with articles and quizzes, earn W1TTS by completing them as well',
      },
      {
        title: '24/7 chat support with our specialists',
        description:
          'Contact with our support any time and let them fix your problem',
      },
      {
        title: 'Unlimited bonuses for your referrals',
        description: 'Earn 60 W1TTS for each 5 friends you’ve invited',
      },
      {
        title: 'Educational support for everyone',
        description:
          'Every student receives help and more than 1.99 euro per month for his education',
      },
    ],
  },
  {
    code: 'MEM02',
    subtitle: 'Customize features & don’t overpay',
    contents: [
      {
        title: 'Customize Your features & don’t overpay',
        description:
          'If you are making a lot of transfers, do currency exchange, or perhaps withdraw money from an ATM? Order Made 2 Measure card and manage the features yourself.',
        buttonText: 'Custom limits',
        buttonColor: '#171717',
        buttonIcon: TimelineRounded,
      },
      {
        title: 'Exclusive card design',
        description:
          'Only in Made 2 Measure you can choose a premium design for your card. A metal card is available for ordering',
        buttonText: 'Premium or metal card',
        buttonColor: '#171717',
        buttonIcon: CreditCardRounded,
      },
    ],
    benefits: [
      {
        title: 'Fully mobile banking for your transactions',
        description: 'No need to wait for the card, use it right now',
      },
      {
        title: 'Unlimited 0 fee card spendings in EEA',
        description: 'Pay everywhere for anything in Europe',
      },
      {
        title: 'Unlimited receiving in EEA',
        description: 'Receive any SEPA transaction without any  hidden fee',
      },
      {
        title: 'Exchange more than 20 currencies',
        description: 'No charge up to €1,000',
      },
      {
        title: '100% free transactions between your contacts',
        description:
          'Send payments to your friends from contact list who have W1TTY account as well',
      },
      {
        title: 'The best opportunities for your transactions around the world',
        description:
          'Send money to almost all countries and select the payment method which will satisfy your needs',
      },
      {
        title: 'Unlimited virtual cards',
        description:
          'Create and use unlimited number of virtual cards with different currencies',
      },
      {
        title: 'The cheapest premium subscription ever',
        description: 'Pay less and only for things that you truly need',
      },
      {
        title: 'Control your fees & limits anytime',
        description:
          'Check, update your limits and fees or even make them smaller. Everything for your comfort ',
      },
      {
        title: 'Unique week goals for everybody',
        description:
          'Achieve your personal goals every week, earn W1TTS and exchange them to real money',
      },
      {
        title: 'Free financial literacy education',
        description:
          'Checkout our huge library with articles and quizzes, earn W1TTS by completing them as well',
      },
      {
        title: '24/7 chat support with our specialists',
        description:
          'Contact with our support any time and let them fix your problem',
      },
      {
        title: 'Unlimited bonuses for your referrals',
        description: 'Earn 60 W1TTS for each 5 friends you’ve invited',
      },

      {
        title: 'Educational support for everyone',
        description:
          'Every student receives help and more than 1.99 euro per month for his education',
      },
    ],
  },
  {
    code: 'BLT01',
    subtitle: 'Get the most out of your Student years!',
    contents: [
      {
        title: 'Pluscribe to student+ card & get €2 monthly',
        description:
          'At the beginning of each month, verified students will be credited with €2',
        buttonText: 'PLUSCRIBE',
        buttonColor: '#8468FC',
        buttonIcon: EuroRounded,
      },
      {
        title: 'Get W1TTY scholarship',
        description:
          "We are committing to support students in their challenging times by offering W1TTY's scholarship program in Lithuania as a start.",
        buttonText: 'Scholarships',
        buttonColor: '#FF5C39',
        buttonIcon: SchoolRounded,
      },
    ],
    benefits: [
      {
        title: 'Fully mobile banking for your transactions',
        description: 'No need to wait for the card, use it right now',
      },
      {
        title: 'Unlimited 0 fee card spendings in EEA',
        description: 'Pay everywhere for anything in Europe',
      },
      {
        title: 'Unlimited receiving in EEA',
        description: 'Receive any SEPA transaction without any  hidden fee',
      },
      {
        title: 'Exchange more than 20 currencies',
        description: 'No charge up to €1,000',
      },
      {
        title: '100% free transactions between your contacts',
        description:
          'Send payments to your friends from contact list who have W1TTY account as well',
      },
      {
        title: 'The best opportunities for your transactions around the world',
        description:
          'Send money to almost all countries and select the payment method which will satisfy your needs',
      },
      {
        title: 'Unlimited virtual cards',
        description:
          'Create and use unlimited number of virtual cards with different currencies',
      },
      {
        title: 'The cheapest premium subscription ever',
        description: 'Pay less and only for things that you truly need',
      },
      {
        title: 'Control your fees & limits anytime',
        description:
          'Check, update your limits and fees or even make them smaller. Everything for your comfort ',
      },
      {
        title: 'Unique week goals for everybody',
        description:
          'Achieve your personal goals every week, earn W1TTS and exchange them to real money',
      },
      {
        title: 'Free financial literacy education',
        description:
          'Checkout our huge library with articles and quizzes, earn W1TTS by completing them as well',
      },
      {
        title: '24/7 chat support with our specialists',
        description:
          'Contact with our support any time and let them fix your problem',
      },
      {
        title: 'Unlimited bonuses for your referrals',
        description: 'Earn 60 W1TTS for each 5 friends you’ve invited',
      },
      {
        title: 'Educational support for everyone',
        description:
          'Every student receives help and more than 1.99 euro per month for his education',
      },
    ],
  },
];
