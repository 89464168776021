import * as actionType from '../actions/actionType';
import { updateObject } from '../../utils/utils';

const initialState = {
  loading: false,
  error: false,
  errorMessage: null,
  cardPlans: [],
  userPlans: {},
};

const getCardPlans = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: false,
    errorMessage: null,
  });
};

const getCardPlansSuccess = (state, action) => {
  const data = action.data;
  return updateObject(state, {
    loading: false,
    cardPlans: [...data],
  });
};

const getUserPlans = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: false,
    errorMessage: null,
  });
};

const getUserPlansSuccess = (state, action) => {
  const data = action.data;
  return updateObject(state, {
    loading: false,
    userPlans: { ...data },
  });
};

export const getError = (state, action) => {
  const {
    error: {
      response: { data },
    },
  } = action;
  return updateObject(state, {
    loading: false,
    error: true,
    errorMessage: data.message,
    ...data,
  });
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionType.GET_CARD_PLANS:
      return getCardPlans(state, action);
    case actionType.GET_CARD_PLANS_SUCCESS:
      return getCardPlansSuccess(state, action);
    case actionType.GET_CARD_PLANS_FAILED:
      return getError(state, action);
    case actionType.GET_USER_PLANS:
      return getUserPlans(state, action);
    case actionType.GET_USER_PLANS_SUCCESS:
      return getUserPlansSuccess(state, action);
    case actionType.GET_USER_PLANS_FAILED:
      return getError(state, action);
    default:
      return state;
  }
};

export default reducer;
