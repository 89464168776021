const { REACT_APP_API_LOGIN, ...others } = process.env;

export const Constants = {
  API: {
    LOGIN: REACT_APP_API_LOGIN,
    VERIFY_OTP: others.REACT_APP_API_VERIFY_OTP,
    ACCOUNT: others.REACT_APP_API_ACCOUNT,
    ACCOUNT_ALL: others.REACT_APP_API_ACCOUNT_ALL,
    CUSTOMER: others.REACT_APP_API_CUSTOMER,
    TRANS_ALL: others.REACT_APP_API_TRANS_ALL,
    CARDS: others.REACT_APP_API_CARDS,
    CARD_DETAILS: others.REACT_APP_API_CARD_DETAILS,
    CARD_SECURED_DETAILS: others.REACT_APP_API_CARD_SECURED_DETAILS,
    BENEFICIARIES: others.REACT_APP_API_BENEFICIARIES,
    NOTIFICATIONS: others.REACT_APP_API_NOTIFICATIONS,
    WCEP_POINTS: others.REACT_APP_API_WCEP_POINTS,
    WCEP_TASKS: others.REACT_APP_API_WCEP_TASKS,
    REWARDS_HISTORY: others.REACT_APP_API_WCEP_REWARDS_HISTORY,
    CARD_PLANS: others.REACT_APP_API_CARD_PLANS,
    USER_PLANS: others.REACT_APP_API_USER_PLANS,
    LIMITS_AND_FEES: others.REACT_APP_API_LIMITS_AND_FEES,
  },
};

export const Routes = {
  LOGIN: others.REACT_APP_ROUTE_LOGIN,
  WELCOME_PAGE: others.REACT_APP_ROUTE_WELCOME_PAGE,
  CARDS_PAGE: others.REACT_APP_ROUTE_CARDS_PAGE,
  ACCOUNT_SUMMARY: others.REACT_APP_ROUTE_ACCOUNT_SUMMARY,
  TRANSACTION_HISTORY: others.REACT_APP_ROUTE_TRANSACTION_HISTORY,
  ACCOUNT_INFO: others.REACT_APP_ROUTE_ACCOUNT_INFO,
  ACCOUNT_VIEW: others.REACT_APP_ROUTE_ACCOUNT_VIEW,
  CARD_DETAILS: others.REACT_APP_ROUTE_CARD_DETAILS,
  BENEFICIARY_MGMT: others.REACT_APP_ROUTE_BENEFICIARY_MGMT,
  PROFILE: others.REACT_APP_ROUTE_PROFILE,
  NOTIFICATIONS: others.REACT_APP_ROUTE_NOTIFICATIONS,
  WITTY_REWARDS: others.REACT_APP_ROUTE_WITTY_REWARDS,
  REWARDS_HISTORY: others.REACT_APP_ROUTE_REWARDS_HISTORY,
  CARD_PLANS: others.REACT_APP_ROUTE_CARD_PLANS,
  CARD_PLAN_INFO: others.REACT_APP_ROUTE_CARD_PLAN_INFO,
  LIMITS_AND_FEES: others.REACT_APP_ROUTE_LIMITS_AND_FEES,
  LIMITS_AND_FEES_INFO: others.REACT_APP_ROUTE_LIMITS_AND_FEES_INFO,
};

export const UrlText = {
  WELCOME_PAGE: 'Accounts',
  CARDS_PAGE: 'Cards',
  ACCOUNT: 'accounts',
  ACCOUNT_SUMMARY: 'Account Summary',
  TRANSACTION_HISTORY: 'Transaction History',
  ACCOUNT_INFO: 'Account Info',
  ACCOUNT_VIEW: 'Account View',
  CARD_DETAILS: 'Card Details',
  BENEFICIARY_MGMT: 'Beneficiary Management',
  PROFILE: 'Profile',
  NOTIFICATIONS: 'Notifications',
  WITTY_REWARDS: 'W1TTY Rewards',
  REWARDS_HISTORY: 'Rewards History',
  CARD_PLANS: 'Card Plans',
  CARD_PLAN_INFO: 'Card Plan Info',
  LIMITS_AND_FEES: 'Limits and Fees',
  LIMITS_AND_FEES_INFO: 'Limits and Fees Info',
};

export const i18nLanguages = [
  {
    text: 'UK English',
    value: 'enGb',
  },
];

export const membershipPlan = [
  {
    code: 'MEM01',
    title: 'Every1',
    description: 'Everyday card plan with all necessary features',
  },
  {
    code: 'MEM02',
    title: 'Made 2 Measure',
    description: 'Customize features & get more what you use',
  },
  {
    code: 'BLT01',
    title: 'Student+',
    description: 'Earn €2 monthly on your account & scholarships',
  },
];

export const limitsAndFees = {
  WLE: 'ATM Withdrawals EEA',
  SWI: 'SWIFT Transfers Non-EEA',
  WLI: 'ATM Withdrawals Non-EEA',
  TPC: 'Top Up by Debit Card',
  CSI: 'Card Spending Non-EEA',
  FEX: 'Currency Exchange',
};
