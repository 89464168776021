import React from 'react';
import {
  makeStyles,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core';
import { getMembership } from '../../../utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(1),
    padding: '6px 10px',
    display: 'inline-flex',
    borderRadius: theme.spacing(2),
    textTransform: 'uppercase',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  buttonText: {
    fontSize: '12px',
  },
  rootList: {
    width: '100%',
  },
  listItem: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(1),
  },
  listPrimaryText: {
    fontWeight: 'bold',
  },
}));

const PlanInfo = (props) => {
  const classes = useStyles();
  const { code, subtitle, contents, benefits } = props;
  const membership = getMembership(code);

  return (
    <>
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h5'>{membership.title}</Typography>
          <Typography variant='body1'>{subtitle}</Typography>
        </Grid>

        {contents?.map((content) => {
          return (
            <Grid item xs={12} key={content.title}>
              <Typography variant='h5'>{content.title}</Typography>
              <Typography variant='body1'>{content.description}</Typography>
              {content.buttonText && (
                <Box
                  bgcolor={content.buttonColor || 'primary.main'}
                  color={content.buttonTextColor || 'primary.contrastText'}
                  className={classes.button}>
                  {content.buttonIcon && (
                    <content.buttonIcon fontSize='small' />
                  )}
                  <Typography
                    variant='subtitle1'
                    className={classes.buttonText}>
                    {content.buttonText}
                  </Typography>
                </Box>
              )}
            </Grid>
          );
        })}

        {benefits.length > 0 && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>ALL W1TTY BENEFITS</Typography>
              </Grid>
              <Grid item xs={12}>
                <List className={classes.rootList} aria-label='W1tty benefits'>
                  <Grid container spacing={2}>
                    {benefits.map((benefit) => {
                      return (
                        <Grid item xs={12} md={6} key={benefit.title}>
                          <ListItem className={classes.listItem}>
                            {benefit.icon && (
                              <ListItemAvatar>
                                <benefit.icon />
                              </ListItemAvatar>
                            )}
                            <ListItemText
                              primary={benefit.title}
                              secondary={benefit.description}
                              classes={{ primary: classes.listPrimaryText }}
                            />
                          </ListItem>
                        </Grid>
                      );
                    })}
                  </Grid>
                </List>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PlanInfo;
