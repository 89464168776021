import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { Loader } from '../../atoms';
import { getAllNotifications } from '../../../store/actions';
import { Notification } from '../../organisms';

const Notifications = (props) => {
  const { getAllNotifications, notifications, loading } = props;

  useEffect(() => {
    getAllNotifications({ screenCategory: 'BELL' });
  }, [getAllNotifications]);

  if (loading) {
    return <Loader />;
  }

  if (notifications.length === 0) {
    return (
      <Grid container justifyContent='center' alignItems='center'>
        <Typography variant='body1'>No new notifications yet</Typography>
      </Grid>
    );
  }

  const groupNotificationsByDate = notifications.reduce((acc, val) => {
    const date = val.createdAt.match(/\d{4}-\d{2}-\d{2}/g).toString();
    const item = acc.find((item) =>
      item.createdAt.match(new RegExp(date, 'g'))
    );
    if (!item)
      acc.push({
        createdAt: val.createdAt,
        notifications: [val],
      });
    else item.notifications.push(val);

    return acc;
  }, []);

  return (
    <>
      <Grid container justifyContent='center' spacing={2}>
        <Grid item xs={12} md={6}>
          <Notification notifications={groupNotificationsByDate} />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { notifications } = state;
  return {
    loading: notifications.loading,
    notifications: notifications.data,
  };
};

const mapDispatchToProps = {
  getAllNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
