import * as actions from '../actions';
import { put } from 'redux-saga/effects';
import { http } from '../../utils/utils';
import { Constants } from '../../utils/constant';

export function* getBeneficiariesSaga(action) {
  try {
    const url = Constants.API.BENEFICIARIES;
    const response = yield http({
      method: 'GET',
      url,
      domain: 'beneficiaries',
    });

    yield put(actions.getBeneficiariesSuccess(response.data));
  } catch (error) {
    yield put(actions.getBeneficiariesFailed(error));
  }
}
