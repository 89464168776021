import { Grid, Typography, makeStyles, Box } from '@material-ui/core';
import { formatAmount } from '../../../utils/utils';
import SemiCircleProgressBar from 'react-progressbar-semicircle';
import { limitsAndFees } from '../../../utils/constant';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 'bold',
  },
  progressContainer: { position: 'relative' },
  progressDesc: {
    position: 'absolute',
    [theme.breakpoints.up('xs')]: {
      inset: '0% 30%  0%',
    },
    [theme.breakpoints.up('md')]: {
      inset: '0% 40%  0%',
    },
    padding: '10px',
    marginTop: '30px',
  },
}));

const LimitsAndFeesInfo = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    feesAndLimit: {
      amountConsumption,
      amountLimit,
      code,
      countConsumption,
      countLimit,
      extraAbsoluteCharge,
      extraPercentageCharge,
    },
  } = props;

  const remainingLimit = countLimit
    ? countLimit - countConsumption
    : amountLimit
    ? Number(amountLimit?.amount) - Number(amountConsumption?.amount)
    : 0;

  let remainingLimitText = 'Monthly limit consumed';
  if (remainingLimit !== 0) {
    remainingLimitText = `${countConsumption ?? amountConsumption?.amount} ${
      code === 'WLE' ? 'withdrawal(s)' : code === 'SWI' ? 'transfer(s)' : ''
    } used out of ${countLimit ?? amountLimit?.amount}`;
  }

  let percentage = 0;
  if (countLimit) {
    percentage = (countConsumption / countLimit) * 100;
  } else if (amountLimit) {
    percentage =
      Number(amountLimit.amount) === 0
        ? 0
        : (Number(amountConsumption.amount) / Number(amountLimit.amount)) * 100;
  }

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item xs={12} md={6}>
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.rootContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5' className={classes.boldText}>
                    {limitsAndFees[code]}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'>{remainingLimitText}</Typography>
                  <Typography variant='body1'>
                    Fee{' '}
                    {extraAbsoluteCharge
                      ? formatAmount(
                          extraAbsoluteCharge.amount,
                          extraAbsoluteCharge.currency
                        )
                      : `${extraPercentageCharge} %`}{' '}
                    per withdrawal
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.rootContainer}>
              <Box className={classes.progressContainer}>
                <SemiCircleProgressBar
                  percentage={percentage}
                  stroke={theme.palette.primary.main}
                />
                <Box className={classes.progressDesc}>
                  <Typography variant='h5' className={classes.boldText}>
                    {countLimit
                      ? countLimit - countConsumption
                      : amountLimit
                      ? formatAmount(
                          Number(amountLimit?.amount) -
                            Number(amountConsumption?.amount),
                          amountLimit?.currency
                        )
                      : 0}
                  </Typography>
                  <Typography variant='body1'>
                    {code === 'WLE'
                      ? 'withdrawals'
                      : code === 'SWI' || code === 'CSI'
                      ? 'transactions'
                      : ''}{' '}
                    remaining this month
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LimitsAndFeesInfo;
