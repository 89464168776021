import {
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATIONS_FAILED,
} from './actionType';

export const getAllNotifications = (params) => {
  const { screenCategory } = params;
  return {
    type: GET_ALL_NOTIFICATIONS,
    payload: { screenCategory },
  };
};

export const getAllNotificationsSuccess = (data) => {
  return {
    type: GET_ALL_NOTIFICATIONS_SUCCESS,
    data,
  };
};

export const getAllNotificationsFailed = (error) => {
  return {
    type: GET_ALL_NOTIFICATIONS_FAILED,
    error,
  };
};
