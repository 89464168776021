import React, { useState } from 'react';
import { Radio, Typography, makeStyles, Grid } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import './Account.scss';
import { getCurrency, capitalizeTxt } from '../../../utils/utils';

const useStyles = makeStyles(() => ({
  radioLabel: {
    fontWeight: 'bold',
  },
}));

const Account = (props) => {
  const classes = useStyles();
  const { accountType, currencyCode, customerFullName, accNumber, iban, bic } =
    props;

  const defaultTab = accountType === 'PRIMARY' ? 'local' : 'international';
  const [tab, setTab] = useState(defaultTab);

  const onRadioChange = (e) => {
    setTab(e.target.value);
  };

  return (
    <div className='main'>
      <Grid container>
        <Grid item xs>
          <Typography className='value' variant='body1' align='left'>
            {capitalizeTxt(accountType)} account -{' '}
            {getCurrency(currencyCode).name ?? currencyCode}
          </Typography>
        </Grid>

        {accountType === 'PRIMARY' && (
          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <RadioGroup
                onChange={onRadioChange}
                defaultValue={'local'}
                row
                aria-label='position'
                name='position'>
                <FormControlLabel
                  value='local'
                  classes={{
                    label: tab === 'local' ? classes.radioLabel : '',
                  }}
                  control={
                    <Radio
                      color='primary'
                      size='small'
                      checked={tab === 'local'}
                    />
                  }
                  label='Local'
                />
                <FormControlLabel
                  value='international'
                  classes={{
                    label: tab === 'international' ? classes.radioLabel : '',
                  }}
                  control={
                    <Radio
                      color='primary'
                      size='small'
                      checked={tab === 'international'}
                    />
                  }
                  label='International'
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}

        <Grid container itemxs={12}>
          {tab === 'local' && (
            <>
              <div className='account-info-tab'>
                <div className='tab-container'>
                  <div className='tab-row'>
                    <Typography className='title' variant='body1' align='left'>
                      Beneficiary
                    </Typography>
                    <Typography className='value' variant='body1' align='left'>
                      {customerFullName}
                    </Typography>
                  </div>
                  <div className='tab-row'>
                    <Typography className='title' variant='body1' align='left'>
                      Account Number
                    </Typography>
                    <Typography className='value' variant='body1' align='left'>
                      {accNumber}
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          )}
          {tab === 'international' && (
            <>
              <div className='account-info-tab'>
                <div className='tab-container'>
                  <div className='tab-row'>
                    <Typography className='title' variant='body1' align='left'>
                      Beneficiary
                    </Typography>
                    <Typography className='value' variant='body1' align='left'>
                      {customerFullName}
                    </Typography>
                  </div>
                  <div className='tab-row'>
                    <Typography className='title' variant='body1' align='left'>
                      IBAN
                    </Typography>
                    <Typography className='value' variant='body1' align='left'>
                      {iban}
                    </Typography>
                  </div>
                  <div className='tab-row'>
                    <Typography className='title' variant='body1' align='left'>
                      SWIFT/BIC
                    </Typography>
                    <Typography className='value' variant='body1' align='left'>
                      {bic}
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Account;
