import React from 'react';
import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
} from '@material-ui/core';
import { Routes } from '../../../utils/constant';
import { Launch } from '@material-ui/icons';
import { Link, Dot } from '../../atoms';
import { useLocation } from 'react-router-dom';
import { formatAccountNum } from '../../../utils/utils.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3) - 4,
    margin: 'auto',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: 'none',
    borderRadius: '10px',
  },
  image: {
    position: 'relative',
    width: '100%',
    minWidth: '180px',
    height: 100,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '10px',
  },
  imageText: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    textAlign: 'center',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    zIndex: 1,
    fontSize: '22px',
  },
  title: {
    fontSize: '14px',
  },
  subTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  link: {
    color: '#0778f6',
    textTransform: 'none',
    fontWeight: 'bold',
    padding: '0px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  dot: {
    marginRight: theme.spacing(1),
  },
}));

const Card = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const { imgLink, cardName, cardNumber, cardType, status, cardId } = props;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={location.pathname === Routes.CARDS_PAGE ? 'auto' : 3}>
            <Box className={`${props.imageContainer || classes.image}`}>
              {status !== 'OPEN' && (
                <Typography component='span' className={classes.imageText}>
                  Card {status === 'CLOSED' ? 'Cancelled' : 'Frozen'}
                </Typography>
              )}
              <img className={classes.img} alt='card' src={imgLink} />
            </Box>
          </Grid>
          <Grid item xs sm container>
            <Grid item xs={12} md container direction='column' spacing={3}>
              <Grid item xs container direction='row' spacing={5}>
                <Grid item xs={12} md='auto'>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    className={classes.title}>
                    Name on the Card
                  </Typography>
                  <Typography
                    gutterBottom
                    variant='subtitle1'
                    color='primary'
                    className={classes.subTitle}>
                    {cardName}
                  </Typography>
                </Grid>
                <Grid item xs={12} md='auto'>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    className={classes.title}>
                    Card No
                  </Typography>
                  <Typography
                    gutterBottom
                    variant='subtitle1'
                    color='primary'
                    className={classes.subTitle}>
                    {cardNumber?.length === 4
                      ? `XXXX XXXX XXXX ${cardNumber}`
                      : formatAccountNum(cardNumber)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md='auto'>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    className={classes.title}>
                    Card Type
                  </Typography>
                  <Typography
                    gutterBottom
                    variant='subtitle1'
                    color='primary'
                    className={classes.subTitle}>
                    {cardType === 'pc' ? 'Physical Card' : 'Virtual Card'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md='auto'>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    className={classes.title}>
                    Card Status
                  </Typography>
                  <Typography
                    gutterBottom
                    variant='subtitle1'
                    color='primary'
                    className={classes.subTitle}>
                    <Dot className={classes.dot} status={status} />{' '}
                    {status === 'OPEN' ? 'Active' : 'Inactive'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {location.pathname === Routes.CARDS_PAGE && (
              <Grid item>
                <Link href={`${Routes.CARD_DETAILS}?cardId=${cardId}`}>
                  <Button className={classes.link} endIcon={<Launch />}>
                    View More Details
                  </Button>
                </Link>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Card;
