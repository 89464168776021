import React from 'react';
import { Typography, Paper, Grid, makeStyles, Box } from '@material-ui/core';
import { Routes } from '../../../utils/constant';
import { useHistory } from 'react-router-dom';
import { Link } from '../../atoms';
import { getMembership } from '../../../utils/utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
}));

const CardPlan = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { cardPlans } = props;

  const handleClickLink = (event, membership) => {
    event.preventDefault();
    const link = `${Routes.CARD_PLAN_INFO}?planCode=${membership.code}`;
    history.push(link);
  };

  return (
    <>
      <Grid container spacing={2}>
        {cardPlans.map((plan) => {
          const { code, fee } = plan;
          const membership = getMembership(code);

          return (
            <Grid item xs={6} md={3} key={code}>
              <Link href='' onClick={(e) => handleClickLink(e, membership)}>
                <Paper className={classes.paper}>
                  <Typography variant='body1'>{membership.title}</Typography>
                  <Box my={1}>
                    <Typography
                      variant='subtitle2'
                      className={classes.subtitle}>
                      {Math.abs(fee) === 0
                        ? 'FREE'
                        : `FROM €${Math.abs(fee)} MONTHLY`}
                    </Typography>
                  </Box>
                  <Typography variant='body2'>
                    {membership.description}
                  </Typography>
                </Paper>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default CardPlan;
