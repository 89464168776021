import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  makeStyles,
  Container,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Logo from '../../../assets/Logo.svg';
import './Login.scss';
import LoginError from '../../organisms/LoginError/LoginError';
import { Routes, Constants } from '../../../utils/constant';
import axios from 'axios';
import {
  setHeader,
  getCookie,
  setCookie,
  validEmailRegex,
  getFullURL,
} from '../../../utils/utils';
import LoginSuccess from '../../organisms/LoginSuccess/LoginSuccess';
import Otp from '../../organisms/Otp/Otp';
import { useTranslation } from 'react-i18next';

import {
  customerFetchStart,
  customerFetchSuccess,
  customerFetchFailed,
} from '../../../store/actions/index';

const INTERCOM_APP_ID = process.env.REACT_APP_CHAT_APP_ID;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  Typography: {
    fontWeight: 'bold',
  },
  logo: {
    marginBottom: theme.spacing(3),
    width: '100px',
  },
  textField: {
    maxWidth: '300px',
  },
  dateField: {
    maxWidth: '300px',
    border: '1px solid #ddd',
  },
  button: {
    padding: '15px',
    minWidth: '250px',
    marginTop: theme.spacing(6),
    borderRadius: '40px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    maxWidth: '300px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
  },
}));

function getCodeBoxElement(index) {
  return document.getElementById('otp' + index);
}

function onFocusEvent(index) {
  for (let item = 1; item < index; item++) {
    const currentElement = getCodeBoxElement(item);
    if (!currentElement.value) {
      currentElement.focus();
      break;
    }
  }
}

const initialState = {
  errors: {
    email: '',
    date: '',
  },
  otpId: null,
  email: null,
  date: null,
  otp: [null, null, null, null],
  errorMassage: null,
  error: false,
  isLoginError: false,
  isAccountLocked: false,
  loading: false,
  isVerifyOtpDisabled: true,
  loginSection: true,
  isOtpError: false,
  isShowLoginSuccess: false,
};

const initialStateOtp = {
  ...initialState,
  loginSection: false,
  isOtpError: false,
};

function Login(props) {
  const history = useHistory();
  const classes = useStyles();
  const [isLoginDisabled, setIsLoginDisabled] = useState(true);
  const [state, setState] = useState(initialState);
  const { t } = useTranslation();
  const { customerFetchStart, firstName, customerLoading } = props;

  const handelSubmit = () => {
    if (validateForm(state.errors)) {
      setState({ ...state, loading: true });
      const data = {
        email: state.email,
        date: state.date,
      };
      const loginUrl = getFullURL(Constants.API.LOGIN);
      axios
        .post(loginUrl, data)
        .then(function (response) {
          const { otpId } = response.data;
          setState({
            ...state,
            loading: false,
            otpId: otpId,
            loginSection: false,
          });
        })
        .catch(function (error) {
          let errorMassage = t('somethingWentWrong');
          if (error?.response?.data?.error === 'gtw-bad_credentials') {
            errorMassage = t('wrongLoginCredentials');
          }
          setState({
            ...state,
            loading: false,
            isAccountLocked: false,
            isLoginError: true,
            errorMassage,
          });
        });
      // setState({...state, loginSection: false});
    }
  };

  function onKeyUpEvent(index, event) {
    const eventCode = event.which || event.keyCode;
    const val = getCodeBoxElement(index).value;
    let otp = [...state.otp];
    otp[index - 1] = val ? Number(val) : null;
    if (val.length === 1) {
      if (index !== 4) {
        getCodeBoxElement(index + 1).focus();
      } else {
        getCodeBoxElement(index).blur();
        // Submit code
      }
    }
    if (eventCode === 8 && index !== 1) {
      getCodeBoxElement(index - 1).focus();
    }
    const isVerifyOtpDisabled = !otp.every((item) => item != null);
    setState({ ...state, otp, isVerifyOtpDisabled });
  }

  const resetLogin = () => {
    setIsLoginDisabled(true);
    if (state.isLoginError) {
      setState(initialState);
    } else {
      setState({
        ...initialStateOtp,
        otpId: state.otpId,
        email: state.email,
        date: state.date,
      });
    }
  };

  const handelChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let errors = state.errors;
    let newState = { ...state };
    switch (name) {
      case 'email':
        errors.email = value.length < 1 ? 'Please enter a email.' : '';
        errors.email = validEmailRegex.test(value)
          ? ''
          : 'Please enter a valid email.';
        break;
      case 'date':
        errors.date = value.length < 1 ? 'Please enter a Date of birth.' : '';
        break;
      default:
        break;
    }
    newState = { ...newState, [name]: value };
    if (validateForm(errors) && newState.email && newState.date) {
      setIsLoginDisabled(false);
      setState({ ...newState, error: true });
    } else {
      setIsLoginDisabled(true);
    }
    setState({ ...newState, errors, errorMassage: null });
  };

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const verifyOtp = () => {
    const data = {
      otpId: state.otpId,
      otpCode: state.otp.join(''),
    };
    setState({ ...state, loading: true });
    const otpUrl = getFullURL(Constants.API.VERIFY_OTP);
    axios
      .post(otpUrl, data)
      .then(function (response) {
        const { accessToken, expires_in = 1800 } = response.data;
        setHeader(accessToken);
        setCookie('key', accessToken, parseInt(expires_in) * 1000);
        setState({ ...state, loading: false });
        // history.push(Routes.WELCOME_PAGE);
        customerFetchStart();
      })
      .catch(function (error) {
        let errorMassage = t('somethingWentWrong');
        if (
          error?.response?.data?.error ===
          ('gtw-invalid_otp' || 'gtw-not_valid')
        ) {
          errorMassage = t('invalidOTP');
        }
        setState({
          ...state,
          loading: false,
          isAccountLocked: false,
          isLoginError: false,
          isOtpError: true,
          errorMassage,
        });
      });
    // history.push(Routes.WELCOME_PAGE);
  };

  const interval = useRef();
  const LoginSuccessClick = () => {
    history.push(Routes.WELCOME_PAGE);
    setState({ ...state, isShowLoginSuccess: false });
    clearTimeout(interval);
  };

  const timer = () => {
    interval.current = setTimeout(() => {
      setState({ ...state, isShowLoginSuccess: false });
      history.push(Routes.WELCOME_PAGE);
      clearTimeout(interval);
    }, 3000);
  };

  useEffect(() => {
    if (getCookie('key') && firstName) {
      setState({ ...state, isShowLoginSuccess: true });
      timer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName]);

  useEffect(() => {
    return () => {
      resetLogin();
      clearTimeout(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getCookie('key')) {
      history.push(Routes.WELCOME_PAGE);
    }
  }, [history]);

  useEffect(() => {
    window.Intercom('boot', {
      app_id: INTERCOM_APP_ID,
    });
  }, []);

  return (
    <Container component='main' maxWidth='xs'>
      {!state.loading && (
        <div className={classes.paper}>
          <Typography
            className={classes.Typography}
            component='h1'
            variant='h5'>
            <img alt='Witty Logo' className={classes.logo} src={Logo} />
          </Typography>
          {state.loginSection && !state.isLoginError && !state.isOtpError && (
            <form className={classes.form} noValidate>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                className={classes.textField}
                onChange={handelChange}
                error={Boolean(state.errors?.email && state.error)}
                autoFocus
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='date'
                label=''
                placeholder='Date of birth'
                type='date'
                format='yyyy/MM/dd/'
                id='date'
                defaultValue={null}
                className={classes.textField}
                onChange={handelChange}
                onSelect={handelChange}
                error={Boolean(state.errors.date && state.error)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
                disabled={isLoginDisabled}
                onClick={handelSubmit}
                endIcon={<ArrowForwardIcon />}>
                SUBMIT
              </Button>
            </form>
          )}
          {!state.loginSection && !state.isOtpError && !customerLoading && (
            <Otp
              onKeyUpEvent={onKeyUpEvent}
              onFocusEvent={onFocusEvent}
              verifyOtp={verifyOtp}
              isVerifyOtpDisabled={state.isVerifyOtpDisabled}
              handelSubmit={handelSubmit}
            />
          )}

          {(state.isLoginError || state.isOtpError) && (
            <LoginError
              isOtpError={state.isOtpError}
              resetLogin={resetLogin}
              errorMassage={state.errorMassage}
              isLocked={state.isAccountLocked}
            />
          )}
        </div>
      )}
      {(state.loading || customerLoading) && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}

      {state.isShowLoginSuccess && firstName && (
        <LoginSuccess firstName={firstName} handelClick={LoginSuccessClick} />
      )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    customerLoading: state.customer.loading,
    firstName: state.customer?.customerProfileDto?.firstName,
    error: state.customer.error,
    errorMassage: state.customer.errorMassage,
  };
};

const mapDispatchToProps = {
  customerFetchStart,
  customerFetchSuccess,
  customerFetchFailed,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
