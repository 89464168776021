import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

export default function MUITextField(props) {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChange(event.target.value);
  };

  return (
    <>
      <TextField
        {...props}
        style={props.style}
        className={props.className}
        value={value}
        onChange={handleChange}
      />
    </>
  );
}
