import React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import classes from './LoginSuccess.module.scss';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const LoginSuccess = (props) => {
  const { t } = useTranslation();

  if (!props.firstName) {
    return null;
  }

  return (
    <div onClick={props.handelClick} className={classes.container}>
      <div>
        <DoneIcon className={classes.icon} />
        <Typography variant='h4'>Hi {props.firstName}!</Typography>
        <Typography variant='body1'>{t('loginSuccess')}</Typography>
      </div>
    </div>
  );
};

export default LoginSuccess;
