import * as actions from '../actions/index';
import { put } from 'redux-saga/effects';
import { http } from '../../utils/utils';
import { Constants } from '../../utils/constant';

export function* getAllTransactionSaga(action) {
  try {
    const { accountId, fromDate, toDate } = action.payload;
    const response = yield http({
      method: 'GET',
      url: Constants.API.TRANS_ALL,
      params: {
        accountId,
        fromDate,
        toDate,
      },
    });
    yield put(actions.getAllTransactionsSuccess(response.data));
  } catch (error) {
    yield put(actions.getAllTransactionsFailed(error));
  }
}
