import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getUserPlans, getLimitsAndFees } from '../../../store/actions';
import { Limits } from '../../organisms';
import { Loader } from '../../atoms';

const LimitsAndFees = (props) => {
  const {
    getUserPlans,
    getLimitsAndFees,
    loading,
    nextBillingDate,
    totalFee,
    feesAndLimits,
  } = props;

  useEffect(() => {
    if (!nextBillingDate || !totalFee) {
      getUserPlans();
    }
  }, [getUserPlans, nextBillingDate, totalFee]);

  useEffect(() => {
    if (feesAndLimits.length === 0) {
      getLimitsAndFees();
    }
  }, [getLimitsAndFees, feesAndLimits]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Limits
        nextBillingDate={nextBillingDate}
        totalFee={totalFee}
        feesAndLimits={feesAndLimits}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    plans: { userPlans },
    limits,
  } = state;
  return {
    loading: limits.loading,
    nextBillingDate: userPlans?.nextBillingDate,
    totalFee: userPlans?.totalFee,
    feesAndLimits: limits.data,
  };
};

const mapDispatchToProps = {
  getUserPlans,
  getLimitsAndFees,
};

export default connect(mapStateToProps, mapDispatchToProps)(LimitsAndFees);
