import * as actionType from "../actions/actionType";
import { updateObject } from "../../utils/utils";

const initialState = {
    loading: false,
    error: false,
}


const fetchCustomerStart = (state) => {
    return updateObject(state, {
        loading: true,
        error: false,
        errorMassage: null
    })
}
const fetchCustomerSuccess = (state, action) => {
    const data = action.data;
    return updateObject(state, {
        loading: false,
        ...data
    })
}
const fetchCustomerFailed = (state, action) => {
    const data = action.data;
    return updateObject(state, {
        ...data,
        error: true,
    })
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionType.CUSTOMER_START:
            return fetchCustomerStart(state, action);
        case actionType.CUSTOMER_SUCCESS:
            return fetchCustomerSuccess(state, action);
        case actionType.CUSTOMER_FAILED:
            return fetchCustomerFailed(state, action);

        default:
            return state;
    }
}

export default reducer;