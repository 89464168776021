import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getRewardsHistory } from '../../../store/actions/wittyRewards';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Grid,
  Box,
  makeStyles,
} from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { Loader } from '../../atoms';

const useStyles = makeStyles((theme) => ({
  rewards: {
    borderRadius: theme.spacing(1),
    textAlign: 'center',
  },
}));

const RewardsHistory = (props) => {
  const classes = useStyles();
  const { getRewardsHistory, loading, rewardsHistory } = props;

  useEffect(() => {
    getRewardsHistory();
  }, [getRewardsHistory]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container justifyContent='center' spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant='h5'>Rewards History</Typography>
          <List component='nav' aria-label='notifications'>
            {rewardsHistory.map((item) => {
              return (
                <ListItem key={item.title + item.type} divider>
                  <ListItemAvatar>
                    <Avatar>
                      <Done />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.title}
                    secondary='Task Completed'
                  />
                  <ListItemAvatar>
                    <Box bgcolor='secondary.main' className={classes.rewards}>
                      <Typography> + {item.reward}</Typography>
                    </Box>
                  </ListItemAvatar>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { wittyRewards } = state;
  return {
    loading: wittyRewards.loading,
    rewardsHistory: wittyRewards?.rewards_history,
  };
};

const mapDispatchToProps = { getRewardsHistory };

export default connect(mapStateToProps, mapDispatchToProps)(RewardsHistory);
