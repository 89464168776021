import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  accountFetchAllStart,
  accountFetchAllFailed,
} from '../../../store/actions/index';
import { Loader } from '../../atoms';
import { parseParams } from '../../../utils/utils';
import classes from './AccountView.module.scss';
import { Transaction } from '../../organisms';

const AccountView = (props) => {
  const { accountId } = parseParams(window.location.search);
  const { list } = props;

  useEffect(() => {
    if (!props.list.length && !props.loading) {
      props.accountFetchAllStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.accountFetchAllFailed, props.list]);

  if (props.loading) return <Loader />;

  return (
    <div className={classes.main}>
      <Transaction list={list} accountId={accountId} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const account = state.account;
  return {
    loading: account.loading,
    list: account.list,
  };
};

const mapDispatchToProps = {
  accountFetchAllStart,
  accountFetchAllFailed,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountView);
