import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link as MUILink } from '@material-ui/core';

const Link = (props) => {
  const history = useHistory();

  const handleClick = (event) => {
    event.preventDefault();
    history.push(props.href);
  };

  return (
    <MUILink
      {...props}
      className={props.className}
      style={props.style}
      onClick={props.onClick || handleClick}
      underline={'none'}
    />
  );
};

export default Link;
