import * as React from 'react';
import {
  Tabs,
  Tab as MUITab,
  makeStyles,
  withStyles,
  Box,
} from '@material-ui/core';

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 30,
      width: '100%',
      backgroundColor: theme.palette.info.main,
    },
    height: 3,
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    opacity: 1,
    minWidth: 100,
  },
  selected: {
    color: theme.palette.info.main,
  },
}))((props) => <MUITab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  tabPanelContainer: {
    padding: '10px 0',
  },
}));

const TabPanel = (props) => {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      className={classes.tabPanelContainer}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const Tab = (props) => {
  const [value, setValue] = React.useState(0);
  const { tabs } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={props.className} style={props.style}>
      <StyledTabs value={value} onChange={handleChange}>
        {tabs.map((o) => (
          <StyledTab key={o.label} label={o.label} />
        ))}
      </StyledTabs>
      {tabs.map((o, i) => {
        return (
          <TabPanel key={o.label} value={value} index={i}>
            {o.component}
          </TabPanel>
        );
      })}
    </div>
  );
};

export default Tab;
