import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { Card } from '../../organisms';
import { accountFetchStart, getCards } from '../../../store/actions';
import { Loader } from '../../atoms';

const Cards = (props) => {
  const { accNumber, loading, cards, getCards, accountFetchStart } = props;
  const activeCards = cards.filter((card) => card.status === 'OPEN').length;
  const freezedCards = cards.filter((card) => card.status !== 'OPEN').length;
  const CARD =
    activeCards > 1 || freezedCards > 1 || activeCards + freezedCards > 0
      ? 'Cards'
      : 'Card';

  useEffect(() => {
    accountFetchStart();
  }, [accountFetchStart]);

  useEffect(() => {
    if (accNumber) {
      getCards(accNumber);
    }
  }, [getCards, accNumber]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Typography variant='body1' align='left'>
        You have <b>{activeCards} Active</b>{' '}
        {freezedCards > 0 && `and ${freezedCards} Freezed`} {CARD}
      </Typography>
      {cards.map((item) => {
        const {
          cardId,
          cardStyle: { imgLink, cardType },
          issueFirstName,
          issueLastName,
          cardNumberLastFourDigits,
          status,
        } = item;
        const cardName = `${issueFirstName} ${issueLastName}`;
        return (
          <Card
            {...item}
            key={cardId}
            imgLink={imgLink}
            cardName={cardName}
            cardNumber={cardNumberLastFourDigits}
            cardType={cardType}
            status={status}
          />
        );
      })}
    </>
  );
};

const mapStateToProps = (state) => {
  const { account, cards } = state;
  return {
    accNumber: account.accNumber,
    loading: account.loading || cards.loading,
    cards: cards.data,
    error: cards.error,
  };
};

const mapDispatchToProps = {
  accountFetchStart,
  getCards,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cards);
