import { connect } from 'react-redux';
import {
  Grid,
  Avatar,
  makeStyles,
  Typography,
  Container,
} from '@material-ui/core';
import { Phone, Mail, LocationOn } from '@material-ui/icons';
import { getCountry } from '../../../utils/utils.js';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(8) * 2,
    height: theme.spacing(8) * 2,
  },
  profDetailsContainer: {
    marginBottom: '10px',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  subTitle: {
    fontWeight: 'bold',
  },
}));

const Profile = (props) => {
  const classes = useStyles();

  const { profilePic, firstName, countryCode, phoneNumber, email, address } =
    props;
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item container justifyContent='center' xs={12} md={3}>
          <Avatar className={classes.large} src={profilePic}>
            <Typography variant='h2'>{firstName?.charAt(0)}</Typography>
          </Avatar>
        </Grid>
        <Grid item container justifyContent='center' xs={12} md={6}>
          <Grid
            container
            spacing={4}
            alignItems='center'
            className={classes.profDetailsContainer}>
            <Grid item xs={2} md={1}>
              <Phone />
            </Grid>
            <Grid item xs={10} md={10}>
              <Typography variant='body2'>Mobile Number</Typography>
              <Typography variant='body1' className={classes.subTitle}>
                {`+${countryCode} ${phoneNumber}`}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={4}
            alignItems='center'
            className={classes.profDetailsContainer}>
            <Grid item xs={2} md={1}>
              <Mail />
            </Grid>
            <Grid item xs={10} md={10}>
              <Typography variant='body2'>Email</Typography>
              <Typography variant='body1' className={classes.subTitle}>
                {email}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={4}
            alignItems='center'
            className={classes.profDetailsContainer}>
            <Grid item xs={2} md={1}>
              <LocationOn />
            </Grid>
            <Grid item xs={10} md={10}>
              <Typography variant='body2'>Residential Address</Typography>
              <Typography variant='body1' className={classes.subTitle}>{`${
                address?.addressLine1
              }, ${address?.addressLine2} ${address?.city}, ${
                address?.postCode
              }, ${getCountry(address?.country)}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { customer } = state;
  return {
    profilePic: state.customer?.customerProfileDto?.profilePic,
    firstName: state.customer?.customerProfileDto?.firstName,
    countryCode: customer?.phoneNumberCountryCode,
    phoneNumber: customer?.shortenPhoneNumber,
    email: customer?.emailAddress,
    address: customer?.customerAddressDto,
  };
};

export default connect(mapStateToProps)(Profile);
