import * as actionType from './actionType';

export const getAllTransactions = (params) => {
  const { accountId, fromDate, toDate } = params;
  return {
    type: actionType.GET_ALL_TRANS,
    payload: {
      accountId,
      fromDate,
      toDate,
    },
  };
};

export const getAllTransactionsSuccess = (data) => {
  return {
    type: actionType.GET_ALL_TRANS_SUCCESS,
    data,
  };
};

export const getAllTransactionsFailed = (error) => {
  return {
    type: actionType.GET_ALL_TRANS_FAILED,
    error,
  };
};
