import * as actionType from "./actionType";

export const accountFetchAllStart = () => {
    return {
        type: actionType.ACCOUNT_ALL_START
    }
}

export const accountFetchAllSuccess = (data) => {
    return {
        data,
        type: actionType.ACCOUNT_ALL_SUCCESS
    }
}

export const accountFetchAllFailed = (error) => {
    return {
        error,
        type: actionType.ACCOUNT_ALL_FAILED
    }
}
export const accountFetchStart = () => {
    return {
        type: actionType.ACCOUNT_START
    }
}

export const accountFetchSuccess = (data) => {
    return {
        data,
        type: actionType.ACCOUNT_SUCCESS
    }
}

export const accountFetchFailed = (error) => {
    return {
        error,
        type: actionType.ACCOUNT_FAILED
    }
}