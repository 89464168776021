import React from 'react';
import {
  Typography,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@material-ui/core';
import classes from './Beneficiary.module.scss';
import { getCountry, getCurrency } from '../../../utils/utils';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  rootAccordionSummary: {
    '& .MuiAccordionSummary-content': {
      flexDirection: 'column',
    },
  },
}));

const Beneficiary = (props) => {
  const {
    beneficiaryName,
    paymentType,
    country,
    currency,
    iban,
    paymentReference,
    paymentReason,
  } = props;
  const muiClasses = useStyles();

  const paymentTypes = {
    regular: 'Bank network',
  };

  return (
    <div className={classes.main}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          classes={{ root: muiClasses.rootAccordionSummary }}>
          <Typography className={classes.title} variant='body1'>
            {beneficiaryName}
          </Typography>
          <Typography variant='body1'>IBAN: {iban}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={6} md>
              <Typography variant='body1'>Payment Type</Typography>
              <Typography className={classes.subTitle} variant='body1'>
                {paymentTypes[paymentType] || paymentType}
              </Typography>
            </Grid>
            <Grid item xs={6} md>
              <Typography variant='body1'>Country </Typography>
              <Typography className={classes.subTitle} variant='body1'>
                {getCountry(country)}
              </Typography>
            </Grid>
            <Grid item xs={6} md>
              <Typography>Currency </Typography>
              <Typography className={classes.subTitle} variant='body1'>
                {getCurrency(currency).name ?? currency}
              </Typography>
            </Grid>
            <Grid item xs={6} md>
              <Typography>Reference</Typography>
              <Typography className={classes.subTitle} variant='body1'>
                {paymentReference}
              </Typography>
            </Grid>
            <Grid item xs={6} md>
              <Typography>Payment Reason</Typography>
              <Typography className={classes.subTitle} variant='body1'>
                {paymentReason}
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Beneficiary;
