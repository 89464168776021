import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
  getWcepPointsBalance,
  getWcepTasks,
} from '../../../store/actions/wittyRewards';
import { Lock, CardGiftcard } from '@material-ui/icons';
import { Loader } from '../../atoms';

const useStyles = makeStyles((theme) => ({
  boldText: { fontWeight: 'bold' },
  cardGiftcardIcon: { fontSize: 'medium' },
  boxContainer: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  rootList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  listItem: {
    backgroundColor: theme.palette.secondary.main,
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
}));

const WittyRewards = (props) => {
  const classes = useStyles();
  const { getWcepPointsBalance, getWcepTasks, loading, pointsBalance, tasks } =
    props;

  useEffect(() => {
    getWcepPointsBalance();
  }, [getWcepPointsBalance]);

  useEffect(() => {
    getWcepTasks();
  }, [getWcepTasks]);

  const PrimaryListItemText = (props) => {
    return (
      <>
        <Grid container direction='row' alignItems='flex-start' spacing={1}>
          <Grid item>
            <CardGiftcard className={classes.cardGiftcardIcon} />
          </Grid>
          <Grid item>
            <Typography variant='body2' className={classes.boldText}>
              {props.wittyPoints} W1TTS
            </Typography>
          </Grid>
        </Grid>
        <Typography className={classes.boldText}>{props.taskName}</Typography>
      </>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item xs={12} md={6}>
          <Grid item>
            <Box className={classes.boxContainer}>
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}>
                    <Grid item>
                      <CardGiftcard />
                    </Grid>
                    <Grid item>
                      <Typography variant='h5' className={classes.boldText}>
                        {pointsBalance} W1TTS
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item>
            <Typography>Your Current Tasks</Typography>
            <List
              component='nav'
              className={classes.rootList}
              aria-label='notifications'>
              {tasks.map((task) => {
                return (
                  <ListItem key={task.id} className={classes.listItem}>
                    <ListItemText
                      primary={
                        <PrimaryListItemText
                          wittyPoints={task.wittyPoints}
                          taskName={task.taskName}
                        />
                      }
                      secondary={`Remaining ${task.activityTotal} to complete`}
                    />

                    <ListItemAvatar>
                      <Avatar>
                        <Lock />
                      </Avatar>
                    </ListItemAvatar>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { wittyRewards } = state;
  return {
    loading: wittyRewards.loading,
    pointsBalance: wittyRewards?.points_balance,
    tasks: wittyRewards?.tasks,
  };
};

const mapDispatchToProps = { getWcepPointsBalance, getWcepTasks };

export default connect(mapStateToProps, mapDispatchToProps)(WittyRewards);
