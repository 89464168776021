import * as actionType from "../actions/actionType";
import { updateObject } from "../../utils/utils";

const initialState = {
    loading: false,
    error: false,
    errorMassage: null,
    list: [],
    accNumber: null,
    balance: null,
    bic: null,
    currencyCode: null,
    customerFullName: null,
    iban: null,
    sortCode: null,
}


const fetchAccountAllStart = (state) => {
    return updateObject(state, {
        loading: true,
        list: [],
        error: false,
        errorMassage: null
    })
}
const fetchAccountAllSuccess = (state, action) => {
    const data = action.data;
    return updateObject(state, {
        loading: false,
        list: [...data],
    })
}
const fetchAccountAllFailed = (state, action) => {
    const data = action.data;
    return updateObject(state, {
        ...data,
        loading: false,
        error: true,
    })
}


const fetchAccountStart = (state) => {
    return updateObject(state, {
        loading: true,
        error: false,
        errorMassage: null
    })
}
const fetchAccountSuccess = (state, action) => {
    const data = action.data;
    return updateObject(state, {
        loading: false,
        ...data
    })
}
const fetchAccountFailed = (state, action) => {
    const data = action.data;
    return updateObject(state, {
        ...data,
        loading: false,
        error: true,
    })
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionType.ACCOUNT_ALL_START:
            return fetchAccountAllStart(state, action);
        case actionType.ACCOUNT_ALL_SUCCESS:
            return fetchAccountAllSuccess(state, action);
        case actionType.ACCOUNT_ALL_FAILED:
            return fetchAccountAllFailed(state, action);
        case actionType.ACCOUNT_START:
            return fetchAccountStart(state, action);
        case actionType.ACCOUNT_SUCCESS:
            return fetchAccountSuccess(state, action);
        case actionType.ACCOUNT_FAILED:
            return fetchAccountFailed(state, action);

        default:
            return state;
    }
}

export default reducer;