import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { Select } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none',
  },
}));

const SelectField = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(props.value || '');

  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChange(event.target.value);
  };

  return (
    <>
      <Select
        {...props}
        style={props.style}
        className={props.className}
        classes={{ ...props.classes }}
        variant={props.variant}
        value={value}
        defaultValue={props.defaultValue}
        onChange={handleChange}
        displayEmpty
        MenuProps={props.MenuProps}>
        <MenuItem value='' disabled className={classes.hidden}>
          {props.placeholder}
        </MenuItem>
        {props.children || props.menuitems}
      </Select>
    </>
  );
};

export default SelectField;
