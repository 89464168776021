import React from 'react';
import { parseParams } from '../../../utils/utils';
import { PlanInfo } from '../../organisms';
import { Plans } from './Plans';

const CardPlanInfo = (props) => {
  const { planCode } = parseParams(window.location.search);
  const plan = Plans.find((obj) => obj.code === planCode);

  if (!plan) return <>No data found</>;
  return (
    <>
      <PlanInfo key={plan.membership} {...plan} />
    </>
  );
};

export default CardPlanInfo;
