import * as actionType from './actionType';

export const getCards = (accountNo) => {
  return {
    type: actionType.GET_CARDS,
    payload: { accountNo },
  };
};

export const getCardsSuccess = (data) => {
  return {
    type: actionType.GET_CARDS_SUCCESS,
    data,
  };
};

export const getCardsFailed = (error) => {
  return {
    type: actionType.GET_CARDS_FAILED,
    error,
  };
};

export const getCardDetails = (cardId) => {
  return {
    type: actionType.GET_CARD_DETAILS,
    payload: { cardId },
  };
};

export const getCardDetailsSuccess = (data) => {
  return {
    type: actionType.GET_CARD_DETAILS_SUCCESS,
    data,
  };
};

export const getCardDetailsFailed = (error) => {
  return {
    type: actionType.GET_CARD_DETAILS_FAILED,
    error,
  };
};

export const getCardSecuredDetails = (cardId) => {
  return {
    type: actionType.GET_CARD_SECURED_DETAILS,
    payload: { cardId },
  };
};

export const getCardSecuredDetailsSuccess = (data) => {
  return {
    type: actionType.GET_CARD_SECURED_DETAILS_SUCCESS,
    data,
  };
};

export const getCardSecuredDetailsFailed = (error) => {
  return {
    type: actionType.GET_CARD_SECURED_DETAILS_FAILED,
    error,
  };
};
