import * as actions from '../actions/index';
import { put } from 'redux-saga/effects';
import { http } from '../../utils/utils';
import { Constants } from '../../utils/constant';

export function* fetchAccountAllSaga() {
  try {
    const responce = yield http({
      method: 'get',
      url: Constants.API.ACCOUNT_ALL,
    });
    yield put(actions.accountFetchAllSuccess(responce.data));
  } catch (error) {
    yield put(actions.accountFetchAllFailed(error));
  }
}

export function* fetchAccountSaga() {
  try {
    const responce = yield http({
      method: 'get',
      url: Constants.API.ACCOUNT,
    });
    yield put(actions.accountFetchSuccess(responce.data));
  } catch (error) {
    yield put(actions.accountFetchFailed(error));
  }
}
