import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getBeneficiaries } from '../../../store/actions';
import { Beneficiary } from '../../organisms';

const BeneficiaryMgmt = (props) => {
  const { getBeneficiaries, beneficiaries } = props;

  useEffect(() => {
    getBeneficiaries();
  }, [getBeneficiaries]);

  return (
    <>
      {beneficiaries.map((item) => {
        const {
          id,
          accountHolderName,
          type: paymentType,
          country,
          currency,
          details: { iban },
          paymentReference,
          paymentReason,
        } = item;
        return (
          <Beneficiary
            key={id}
            beneficiaryName={accountHolderName}
            paymentType={paymentType}
            country={country}
            currency={currency}
            iban={iban}
            paymentReference={paymentReference}
            paymentReason={paymentReason}
          />
        );
      })}
    </>
  );
};

const mapStateToProps = (state) => {
  const { beneficiaries } = state;
  return {
    loading: beneficiaries.loading,
    beneficiaries: beneficiaries.data,
  };
};

const mapDispatchToProps = {
  getBeneficiaries,
};

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryMgmt);
