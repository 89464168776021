import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import { http } from '../../utils/utils';
import { Constants } from '../../utils/constant';

export function* getAllNotificationsSaga(action) {
  try {
    const { screenCategory } = action.payload;
    const response = yield http({
      method: 'GET',
      url: Constants.API.NOTIFICATIONS,
      params: {
        screenCategory,
      },
    });
    yield put(actions.getAllNotificationsSuccess(response.data));
  } catch (error) {
    yield put(actions.getAllNotificationsFailed(error));
  }
}
