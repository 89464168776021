import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  MenuItem,
  Menu,
  Avatar,
} from '@material-ui/core';
import {
  MoreVert as MoreIcon,
  NotificationsNoneOutlined,
  AccountCircle,
  OutlinedFlag,
  ChangeHistory,
} from '@material-ui/icons';
import Logo from '../../../assets/Logo.svg';
import { Logout, getCookie } from '../../../utils/utils';
import { Routes, UrlText } from '../../../utils/constant';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { customerFetchStart } from '../../../store/actions/index';
import './Header.scss';
import { Link } from 'react-router-dom';

const INTERCOM_APP_ID = process.env.REACT_APP_CHAT_APP_ID;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  alignNavItems: {
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    paddingLeft: '50px',
  },
  mainLink: {
    color: '#535e74',
    marginRight: '50px',
    textDecoration: 'none',
  },
  active: {
    color: '#0778f6',
    borderBottom: '2px solid #0778f6',
  },
}));

const Header = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const {
    customerFetchStart,
    firstName,
    profilePic,
    name,
    email,
    userId,
    createdAt,
  } = props;

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const navList = ['Accounts', 'Cards'];
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (!getCookie('key')) {
      history.push(Routes.LOGIN);
    }
  }, [history]);

  useEffect(() => {
    window.Intercom('boot', {
      app_id: INTERCOM_APP_ID,
      name: name,
      email: email,
      created_at: createdAt,
      user_id: userId,
    });
  }, [name, email, createdAt, userId]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const logout = () => {
    handleMenuClose();
    Logout();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const renderNav = () => {
    const pathName = window.location.pathname.toLocaleLowerCase();
    return (
      <ul className='nav-container'>
        {navList.map((item) => {
          let cssClass = 'nav-list';
          if (
            pathName.indexOf(item.toLocaleLowerCase()) !== -1 ||
            (pathName.indexOf(UrlText.WELCOME_PAGE) !== -1 &&
              item.toLocaleLowerCase() === UrlText.ACCOUNT)
          ) {
            cssClass = cssClass + ' active';
          }
          return (
            <li key={item.replace(' ', '_')} className={cssClass}>
              <Link
                className={`${classes.mainLink}`}
                to={`/${item.toLocaleLowerCase()}`}>
                {item}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <MenuItem onClick={handleMenuClose} component={Link} to={Routes.PROFILE}>
        {UrlText.PROFILE}
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        component={Link}
        to={Routes.LIMITS_AND_FEES}>
        {UrlText.LIMITS_AND_FEES}
      </MenuItem>
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem
        onClick={handleMobileMenuClose}
        component={Link}
        to={Routes.WITTY_REWARDS}>
        <IconButton aria-label='show rewards' color='inherit'>
          <Badge color='primary'>
            <OutlinedFlag />
          </Badge>
        </IconButton>
        <p>{UrlText.WITTY_REWARDS}</p>
      </MenuItem>
      <MenuItem
        onClick={handleMobileMenuClose}
        component={Link}
        to={Routes.REWARDS_HISTORY}>
        <IconButton aria-label='show rewards history' color='inherit'>
          <Badge color='primary'>
            <ChangeHistory />
          </Badge>
        </IconButton>
        <p>{UrlText.REWARDS_HISTORY}</p>
      </MenuItem>
      <MenuItem
        onClick={handleMobileMenuClose}
        component={Link}
        to={Routes.NOTIFICATIONS}>
        <IconButton aria-label='show notifications' color='inherit'>
          <Badge color='primary'>
            <NotificationsNoneOutlined />
          </Badge>
        </IconButton>
        <p>{UrlText.NOTIFICATIONS}</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'>
          <AccountCircle />
        </IconButton>
        <p>{UrlText.PROFILE}</p>
      </MenuItem>
    </Menu>
  );
  useEffect(() => {
    if (!firstName) {
      customerFetchStart();
    }
  }, [customerFetchStart, firstName]);

  return (
    <div className={classes.grow}>
      <AppBar position='static' color='secondary' elevation={0}>
        <Toolbar>
          <Typography className={classes.title} variant='h6' noWrap>
            <img alt='Witty Logo' width='75px' src={Logo} />
          </Typography>
          <div className={`${classes.grow} ${classes.alignNavItems}`}>
            {renderNav()}
          </div>
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label='show rewards'
              color='inherit'
              component={Link}
              to={Routes.WITTY_REWARDS}>
              <Badge color='primary'>
                <OutlinedFlag />
              </Badge>
            </IconButton>
            <IconButton
              aria-label='show rewards history'
              color='inherit'
              component={Link}
              to={Routes.REWARDS_HISTORY}>
              <Badge color='primary'>
                <ChangeHistory />
              </Badge>
            </IconButton>
            <IconButton
              aria-label='show new notifications'
              color='inherit'
              component={Link}
              to={Routes.NOTIFICATIONS}>
              <Badge color='primary'>
                <NotificationsNoneOutlined />
              </Badge>
            </IconButton>
            <IconButton
              edge='end'
              aria-label='account of current user'
              aria-controls={menuId}
              aria-haspopup='true'
              onClick={handleProfileMenuOpen}
              color='inherit'>
              {/* <AccountCircle /> */}
              <Avatar sizes='30px' src={profilePic}>
                {firstName?.charAt(0)}
              </Avatar>

              <ExpandMoreIcon />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label='show more'
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              onClick={handleMobileMenuOpen}
              color='inherit'>
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    customer,
    customer: { customerProfileDto },
  } = state;

  return {
    profilePic: customerProfileDto?.profilePic,
    firstName: customerProfileDto?.firstName,
    name: `${customerProfileDto?.firstName} ${customerProfileDto?.lastName}`,
    email: customer?.emailAddress,
    userId: customer?.userId,
    createdAt: customer?.createdDate,
  };
};
const mapDispatchToProps = { customerFetchStart };

export default connect(mapStateToProps, mapDispatchToProps)(Header);
