import * as actionType from '../actions/actionType';
import { updateObject } from '../../utils/utils';

const initialState = {
  loading: false,
  error: false,
  errorMessage: null,
  txHistory: [],
};

const getAllTrans = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: false,
    errorMessage: null,
  });
};

const getAllTransSuccess = (state, action) => {
  const data = action.data;
  return updateObject(state, {
    loading: false,
    txHistory: [...data],
  });
};

const getAllTransFailed = (state, action) => {
  const data = action.data;
  return updateObject(state, {
    loading: false,
    error: true,
    ...data,
  });
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionType.GET_ALL_TRANS:
      return getAllTrans(state, action);
    case actionType.GET_ALL_TRANS_SUCCESS:
      return getAllTransSuccess(state, action);
    case actionType.GET_ALL_TRANS_FAILED:
      return getAllTransFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
