import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import classes from './AccountSummary.module.scss';
import { getCurrency, formatAccountNum } from '../../../utils/utils';
import { Link } from '../../atoms';
import { Routes } from '../../../utils/constant';

const AccountSummary = (props) => {
  return (
    <div className={classes.main}>
      <Grid container spacing={3}>
        <Grid item xs={6} md={2}>
          <Typography className={classes.title} variant='body1' align='left'>
            {props.currencyCode} Account{' '}
          </Typography>
          <Typography className={classes.value} variant='body1' align='left'>
            {formatAccountNum(props.accountNum)}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography className={classes.title} variant='body1' align='left'>
            Current Balance
          </Typography>
          <Typography className={classes.value} variant='body1' align='left'>
            {getCurrency(props.currencyCode)?.symbol} {props.balance}
          </Typography>
        </Grid>
        <Grid item xs={2} container alignItems='flex-end'>
          <Link
            href={`${Routes.ACCOUNT_VIEW}?accountId=${props.accountId}`}
            className={classes.link}>
            View
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default AccountSummary;
