import * as actions from '../actions/index';
import { put } from 'redux-saga/effects';
import { http } from '../../utils/utils';
import { Constants } from '../../utils/constant';

export function* getLimitsAndFeesSaga() {
  try {
    const response = yield http({
      method: 'GET',
      url: Constants.API.LIMITS_AND_FEES,
    });

    yield put(actions.getLimitsAndFeesSuccess(response.data));
  } catch (error) {
    yield put(actions.getLimitsAndFeesFailed(error));
  }
}
