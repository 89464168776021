import './App.scss';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Routes } from './utils/constant';
import Layout from './component/templates/Layout/Layout';
import {
  AccountInfo,
  AccountView,
  BeneficiaryMgmt,
  CardDetails,
  CardPlanInfo,
  CardPlans,
  Cards,
  Dashboard,
  LimitsAndFees,
  LimitsAndFeesInfo,
  Login,
  Notifications,
  Profile,
  RewardsHistory,
  TransactionHistory,
  WittyRewards,
} from './component/pages';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#535e74',
        dark: '#535e74',
        light: '#cbced5',
        color: '#535e74',
      },
      secondary: {
        main: '#ededed',
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Layout>
          <Switch>
            <Route
              exact={true}
              path={[Routes.WELCOME_PAGE, Routes.ACCOUNT_SUMMARY]}>
              <Dashboard />
            </Route>
            <Route path={Routes.PROFILE}>
              <Profile />
            </Route>
            <Route path={Routes.WITTY_REWARDS}>
              <WittyRewards />
            </Route>
            <Route path={Routes.REWARDS_HISTORY}>
              <RewardsHistory />
            </Route>
            <Route exact path={Routes.LIMITS_AND_FEES}>
              <LimitsAndFees />
            </Route>
            <Route path={Routes.LIMITS_AND_FEES_INFO}>
              <LimitsAndFeesInfo />
            </Route>
            <Route exact path={Routes.CARDS_PAGE}>
              <Cards />
            </Route>
            <Route exact path={Routes.CARD_PLANS}>
              <CardPlans />
            </Route>
            <Route exact path={Routes.CARD_PLAN_INFO}>
              <CardPlanInfo />
            </Route>
            <Route path={Routes.CARD_DETAILS}>
              <CardDetails />
            </Route>
            <Route path={Routes.NOTIFICATIONS}>
              <Notifications />
            </Route>
            <Route path={Routes.ACCOUNT_INFO}>
              <AccountInfo />
            </Route>
            <Route path={Routes.TRANSACTION_HISTORY}>
              <TransactionHistory />
            </Route>
            <Route path={Routes.ACCOUNT_VIEW}>
              <AccountView />
            </Route>
            <Route path={Routes.BENEFICIARY_MGMT}>
              <BeneficiaryMgmt />
            </Route>
            <Route path={Routes.LOGIN}>
              <Login />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
