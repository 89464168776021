import React from 'react';
import { Container, makeStyles, Typography, Button } from '@material-ui/core';
import { Routes } from '../../../utils/constant';
import { useTranslation } from 'react-i18next';

const { REACT_APP_WITTY_WEB } = process.env;
const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  Typography: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  button: {
    padding: '15px',
    minWidth: '250px',
    marginTop: theme.spacing(6),
    borderRadius: '40px',
  },
}));
function LoginError(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const tryAgain = () => {
    const resetLogin = props.resetLogin;
    if (resetLogin) {
      resetLogin();
    } else {
      window.location.href = Routes.LOGIN;
    }
  };
  const closeLogin = () => {
    window.location.href = REACT_APP_WITTY_WEB;
  };

  const newCode = () => {
    //
  };
  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
        {!props.isLocked && (
          <Typography
            className={classes.Typography}
            component='h6'
            variant='h6'>
            {props.errorMassage || t('wrongLoginCredentials')}
          </Typography>
        )}
        {props.isLocked && (
          <Typography
            className={classes.Typography}
            component='h6'
            variant='h6'>
            {t('wrongOTP5Times')}
          </Typography>
        )}

        {!props.isLocked && (
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            onClick={tryAgain}>
            Try again
          </Button>
        )}
        {props.isOtpError && (
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            onClick={newCode}>
            New Code
          </Button>
        )}
        <Button
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={closeLogin}>
          Close
        </Button>
      </div>
    </Container>
  );
}

export default LoginError;
